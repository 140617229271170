import { ISalesSowMetaItem } from '@EngagementsSowData/salesSow.model';
import {
  GET_SALES_SOW_META_REQUEST,
  GET_SALES_SOW_META_SUCCESS,
  GET_SALES_SOW_META_FAILURE,
} from 'core/actions/Sales/GetSalesSowMeta/actionsTypes';

interface ISalesSowMetaState {
  isLoading: boolean;
  meta: ISalesSowMetaItem[];
  error: any;
}

const initialState: ISalesSowMetaState = {
  isLoading: true,
  meta: [],
  error: {},
};

const getSalesSowMetaReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_SALES_SOW_META_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (type === GET_SALES_SOW_META_SUCCESS) {
    return {
      ...state,
      meta: data,
      isLoading: false,
    };
  }

  if (type === GET_SALES_SOW_META_FAILURE) {
    return {
      ...state,
      isLoading: false,
      error,
    };
  }

  return state;
};

export default getSalesSowMetaReducer;
