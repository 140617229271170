import React from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import CustomModal from 'shared/CustomModal';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import OrderModalItem from 'shared/OrderModalItem';

import './CancelOrderModal.scss';

const CancelOrderModal = ({ activeRow, cleanActiveRow }) => {
  const { submitForm } = useFormikContext();

  const onClose = () => {
    cleanActiveRow();
  };

  return (
    <CustomModal
      title="Are you sure you want to cancel the selected order?"
      isModalOpen={!!activeRow}
      onClickConfirm={submitForm}
      onCloseModal={onClose}
      areButtonsVisible
      maxWidth="504px"
      isConfirmDialog
      confirmButtonText="Yes"
      cancelButtonText="No"
    >
      <Grid
        className="cancel-order__modal"
        container
        item
      >
        <OrderModalItem
          location={activeRow.customerLocation || activeRow.location}
          product={activeRow.product}
        />
        <div className="cancel-order__modal__reason-title">Cancellation reason.</div>
        <FormikTextareaField
          fieldName="text"
        />
      </Grid>
    </CustomModal>
  );
};

export default CancelOrderModal;
