import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { ISowDataListItem, ORDER_TYPE, PmEngagementType } from '@EngagementsSowData/sowData.model';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { checkIfOrderNonAttTSD } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsSowData/utilities';

interface IWirelineEngagementsSowDataTableRowActionsProps {
  row: ISowDataListItem
}

const { BILLING_SPECIALIST, PROJECT_MANAGER, CONTRACT_SPECIALIST } = UserTeamRoleTeamDepartment;

const WirelineEngagementsSowDataTableRowActions: React.FC<
  IWirelineEngagementsSowDataTableRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,

    setActiveRowEdit,
    setActiveRowEscalationSupport,
    setActiveRowCancelOrder,
    setActiveRowView,
    setActiveRowComment,
  } = useTableActiveRowContext<ISowDataListItem>();

  const rowId = get(row, 'id');
  const isCanceled = get(row, 'contractOrderSupport') === ORDER_TYPE.CANCELED
      || get(row, 'pmEngagementType') === PmEngagementType.CANCELED;
  const isRowNonAttTSD = checkIfOrderNonAttTSD(row);

  const {
    isEscalationSupportMode, isCancelOrderMode, isCommentMode,
  } = getTableRowModeBoolean(activeRowMode);

  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isEscalationSupportBtnSelected = isEscalationSupportMode && rowId === activeRowId;
  const isCancelButtonSelected = isCancelOrderMode && rowId === activeRowId;
  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;

  const isNonAttTSDForRoles = (
    isBillingSpecialist
    || isProjectManager
    || isContractSpecialist
  ) && isRowNonAttTSD;

  const isEscalationSupportBtnDisabled = (
    (!!activeRowId && !isEscalationSupportBtnSelected) || isCanceled
  );
  const isCancelButtonDisabled = (
    (!!activeRowId && !isCancelButtonSelected)
    || isCanceled || isBillingSpecialist
  );
  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;

  const isAllActionButtonDisabled = isEscalationSupportBtnDisabled
   && isCancelButtonDisabled
   && isCommentButtonDisabled;

  const isMoreButtonDisabled = isAllActionButtonDisabled || isNonAttTSDForRoles;

  const isViewButton = isCanceled || isNonAttTSDForRoles;

  const moreButtonConfig = compact([
    {
      onClick: () => setActiveRowEscalationSupport(row),
      isDisabled: isEscalationSupportBtnDisabled,
      icon: <NextWeekOutlinedIcon />,
      text: 'Escalation Support',
    },
    {
      onClick: () => setActiveRowCancelOrder(row),
      isDisabled: isCancelButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel Order',
    },
    {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    },
  ]);

  return (
    <Actions<ISowDataListItem>
      row={row}
      activeRowId={activeRowId}
      isViewButton={isViewButton}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isMoreButtonDisabled={isMoreButtonDisabled}
    />
  );
};

export default WirelineEngagementsSowDataTableRowActions;
