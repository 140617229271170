import React, { useState } from 'react';
import get from 'lodash/get';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import ActiveNumbersTable from '@EngagementsActiveNumbers/ActiveNumbersTable';
import { useParams } from 'react-router-dom';
import getSalesActiveNumbersAction from 'core/actions/Sales/GetSalesActiveNumbers/actions';
import { TableProvider } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { TRow } from 'shared/Table/Table.models';
import { tableIds } from 'shared/Table/constants';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import {
  activeNumbersTitle,
  columns,
  endpointToGetActiveNumbersListSchemaName,
  engagementAcceptedDateKey,
} from '@EngagementsActiveNumbers/constants';
import ContentShrink from 'shared/ContentShrink';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { useAppSelector } from 'core/store';
import Loader from 'shared/Loader';
import { getColumnsFromOpenapiSchema } from 'core/utilities/openapiSchema/getColumnsFromOpenapiSchema';
// eslint-disable-next-line no-unused-vars
import { IRequestParams } from '@EngagementsActiveNumbers/activeNumbers.model';
import { ORDER_TYPE } from '@EngagementsSowData/sowData.model';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import './WirelineEngagementsActiveNumbers.scss';

const WirelineEngagementsActiveNumbersContainer = () => {
  const { customerId } = useParams();
  const [locationId, setLocationId] = useState<number | null>(null);

  const isOpenapiSchemasLoading = (useAppSelector((state) => state.openapiSchemas.isLoading));
  const schema = useAppSelector((state) => state.openapiSchemas.schemas);
  const openApiPaths = useAppSelector((state) => state.openapiSchemas.paths);

  const activeNumbersColumns = getColumnsFromOpenapiSchema(
    schema,
    openApiPaths,
    endpointToGetActiveNumbersListSchemaName,
    columns,
  );

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext();
  const { isEditMode, isViewMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const { isEditingAvailable, isCancelOrderAvailable } = useUserPermissions();

  const enableRowSelection = (
    (row) => (
      isEditingAvailable
      && !get(row, `original.${engagementAcceptedDateKey}`)
      && get(row, 'original.orderType') !== ORDER_TYPE.CANCELED
    )
  );

  const isTableShrank = isEditMode || isViewMode || isCommentMode;

  if (!customerId) {
    return null;
  }

  const requestParams: IRequestParams = {
    customerId,
    locationId,
  };

  if (isOpenapiSchemasLoading) {
    return <Loader />;
  }

  return (
    <div className="active-numbers">
      <BackToEditEngagementButton title={activeNumbersTitle} />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<IRequestParams>
          id={tableIds.activeNumbers}
          columns={activeNumbersColumns as ColumnDef<TRow>[]}
          getDataAction={getSalesActiveNumbersAction}
          requestParams={requestParams}
          enableEditing={isEditingAvailable || isCancelOrderAvailable}
          enableRowSelection={enableRowSelection}
        >
          <ActiveNumbersTable
            setLocationId={setLocationId}
            locationId={locationId}
          />
        </TableProvider>
      </ContentShrink>
    </div>
  );
};

export default WirelineEngagementsActiveNumbersContainer;
