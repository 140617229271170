import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import EditSaveControls from 'shared/EditSaveControls';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import EngagementsFormGeneralEngagement from '@EngagementsForm/EngagementsFormGeneral/EngagementsFormGeneralEngagement';
import EngagementsFormGeneralCustomerInformation from '@EngagementsForm/EngagementsFormGeneral/EngagementsFormGeneralCustomerInformation';
import AddProductButtonBlock from '@EngagementsForm/AddProductButtonBlock';

const { BILLING_SPECIALIST, CONTRACT_SPECIALIST, RETENTION_MANAGER } = UserTeamRoleTeamDepartment;

const EngagementsFormGeneral = () => {
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const { wirelineEngagementId } = useParams();
  const { submitForm, resetForm } = useFormikContext();
  const {
    isEditModeGeneral, isEditModeScopeDescription, setIsEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const isCreateMode = !wirelineEngagementId;
  const isEditControlsDisabled = isEditModeScopeDescription
      || isBillingSpecialist || isContractSpecialist || isRetentionManager;

  return (
    <Grid
      className="engagements-form__section"
      container
      direction="row"
      columnSpacing={5}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Typography
          className="engagements-form__section-title"
          variant="body1"
          component="p"
        >
          Customer Information
        </Typography>
        <EditSaveControls
          onCancelEdit={resetForm}
          onSave={submitForm}
          isEditMode={isEditModeGeneral}
          setIsEditMode={setIsEditModeGeneral}
          isCreateMode={isCreateMode}
          isDisabled={isEditControlsDisabled}
        />
      </Grid>
      <EngagementsFormGeneralEngagement />
      <EngagementsFormGeneralCustomerInformation />
      <AddProductButtonBlock />
    </Grid>
  );
};

export default EngagementsFormGeneral;
