import React from 'react';
import CustomModal from 'shared/CustomModal';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import engagementAcceptedAction from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAccepted';
import EngagementAcceptedItems
  from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedModal/EngagementAcceptedItems';
import {
  buttonWidth,
  engagementAcceptedButtonText,
  engagementAcceptedModalTitle, engagementAcceptedNotifierMessage,
} from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';

interface IEngagementAcceptedModalProps {
  isModalOpen: boolean;
  onCloseModal: ()=> void;
  selectedActiveNumbers: IActiveNumbersItem[];
}

const EngagementAcceptedModal: React.FC<IEngagementAcceptedModalProps> = (
  { isModalOpen, onCloseModal, selectedActiveNumbers },
) => {
  const { rowSelection, getTableData } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const selectedRowIds = Object.keys(rowSelection);

  const onEngagementAccepted = async () => {
    await Promise.all(selectedRowIds.map(
      (id) => dispatch(engagementAcceptedAction(id))
        .unwrap()
        .then(() => {
          showSuccessNotifier(engagementAcceptedNotifierMessage.success);
        })
        .catch((e) => {
          showErrorNotifier(engagementAcceptedNotifierMessage.error, e);
        })),
    );

    getTableData();
    onCloseModal();
  };

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      onCloseModal={onCloseModal}
      title={engagementAcceptedModalTitle}
      confirmButtonText={engagementAcceptedButtonText.confirmButtonText}
      cancelButtonText={engagementAcceptedButtonText.cancelButtonText}
      onClickConfirm={onEngagementAccepted}
      areButtonsVisible
      buttonWidth={buttonWidth}
    >
      <EngagementAcceptedItems
        selectedActiveNumbers={selectedActiveNumbers}
      />
    </CustomModal>
  );
};

export default EngagementAcceptedModal;
