const crm = 'crm';
const engagements = 'engagements';
const charts = 'charts';
const sales = 'sales';

const generalEngagement = 'general-engagement/';
const projectAcceptance = 'project-acceptance/';
const projectStatus = 'project-status/';

const periods = 'periods/';
const periodsMrc = 'periods-mrc/';
const revenue = 'revenue/';
const totals = 'totals/';

const transactionalOrders = 'transactional-orders/';
const openRequests = 'open-requests/';

const billingRequestType = 'billing-request-type/';
const billingEngagementStatus = 'billing-engagement-status/';

const technicalOverlay = 'technical-overlay/';

const booking = 'booking/';
const escalationSupport = 'escalation-support';

const retention = 'retention/';
const retentionTotal = 'retention-total/';

const insideSalesEngagementType = 'inside-sales-engagement-type/';
const insideSalesUsers = 'inside-sales-users/';
const insideSalesBooking = 'inside-sales-request-booking';

const escalationSupportTotal = 'escalation-request-status/';
const escalationSupportEscalationType = 'escalation-request-type/';

export const getEngagementsTotalOrdersUrl = `${crm}/${engagements}/${charts}/${totals}`;

export const getEngagementsRevenueUrl = `${crm}/${engagements}/${charts}/${revenue}`;

export const getEngagementsOrdersMrcUrl = `${crm}/${engagements}/${charts}/${periodsMrc}`;

export const getEngagementsOrdersByPeriodsUrl = `${crm}/${engagements}/${charts}/${periods}`;

export const getProjectStatusUrl = `${crm}/${engagements}/${charts}/${projectStatus}`;

export const getProjectAcceptanceUrl = `${crm}/${engagements}/${charts}/${projectAcceptance}`;

export const getGeneralEngagementUrl = `${crm}/${engagements}/${charts}/${generalEngagement}`;

export const getTransactionalOrdersUrl = `${crm}/${engagements}/${charts}/${transactionalOrders}`;

export const getOpenRequestsUrl = `${crm}/${engagements}/${charts}/${openRequests}`;

export const getBillingRequestTypeChartUrl = `${charts}/${billingRequestType}`;

export const getBillingEngagementStatusChartUrl = `${charts}/${billingEngagementStatus}`;

export const getTechnicalOverlayUrl = `${charts}/${technicalOverlay}`;

export const getBookingDataUrl = `${sales}/${booking}`;

export const getEscalationSupportUrl = `${crm}/${engagements}/${escalationSupport}/`;

export const getRetentionDataUrl = `${charts}/${retention}`;

export const getRetentionTotalDataUrl = `${charts}/${retentionTotal}`;

export const getRequestStatusUrl = `${charts}/${insideSalesEngagementType}`;

export const getInsideSalesUsersUrl = `${charts}/${insideSalesUsers}`;

export const insideSalesBookingUrl = `${crm}/${insideSalesBooking}/`;

export const getEscalationSupportTotalUrl = `${charts}/${escalationSupportTotal}`;

export const getEscalationSupportEscalationTypeUrl = `${charts}/${escalationSupportEscalationType}`;
