import { netsparkAxios } from 'core/services/HTTPService';
import {
  IEngagementDraft,
  IEngagement,
  IEngagementComment,
  IEngagementAttachment,
} from 'pages/WirelineEngagements/WirelineEngagements.models';
import {
  engagementsPath,
  draftPath,
  getEngagementCommentsUrl,
  getEngagementEditCommentsUrl,
  getEngagementAttachmentsUrl,
  getEngagementEditAttachmentsUrl,
} from 'core/api/constants';
// eslint-disable-next-line no-unused-vars
import { IDraftComment } from 'shared/widgets/Comments/Comments.models';
// eslint-disable-next-line no-unused-vars
import { IDraftAttachment } from 'shared/widgets/Attachments/Attachments.model';

export const getEngagements = () => netsparkAxios.get(engagementsPath);

export const getEngagementById = (id: string | number) => netsparkAxios.get<IEngagement>(`${engagementsPath}${id}/`);

export const createEngagementDraft = () => netsparkAxios.post<IEngagementDraft>(`${engagementsPath}${draftPath}`);

export const updateEngagementById = (id, params) => netsparkAxios.put(`${engagementsPath}${id}/`, params);

export const getEngagementComments = (signal: AbortSignal, engagementId: string | number) => (
  netsparkAxios.get<IEngagementComment[]>(getEngagementCommentsUrl(engagementId), { signal })
);

export const createEngagementComment = (
  engagementId: string | number,
  formData: IDraftComment,
) => (
  netsparkAxios.post<IEngagementComment>(getEngagementCommentsUrl(engagementId), formData)
);

export const editEngagementComment = (
  engagementId: string | number,
  commentId: string | number,
  formData: IDraftComment,
) => (
  netsparkAxios.put<IEngagementComment>(getEngagementEditCommentsUrl(
    engagementId, commentId), formData)
);

export const removeEngagementComment = (
  engagementId: string | number,
  commentId: string | number,
) => (
  netsparkAxios.delete(getEngagementEditCommentsUrl(engagementId, commentId))
);

export const getEngagementAttachments = (signal: AbortSignal, engagementId: string | number) => (
  netsparkAxios.get<IEngagementAttachment[]>(getEngagementAttachmentsUrl(engagementId), { signal })
);

export const createEngagementAttachment = (
  engagementId: string | number,
  attachmentFile: FormData,
) => (
  netsparkAxios.post(getEngagementAttachmentsUrl(engagementId), attachmentFile)
);

export const editEngagementAttachmentName = (
  engagementId: string | number,
  attachmentId: string | number,
  draftAttachment: IDraftAttachment,
) => (
  netsparkAxios.put<IEngagementAttachment>(getEngagementEditAttachmentsUrl(
    engagementId, attachmentId), draftAttachment)
);

export const removeEngagementAttachment = (
  engagementId: string | number,
  attachmentId: string | number,
) => (
  netsparkAxios.delete(getEngagementEditAttachmentsUrl(engagementId, attachmentId))
);
