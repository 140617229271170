import React from 'react';
import cx from 'classnames';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { TRow } from 'shared/Table/Table.models';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
import MoneyCell from 'shared/Table/shared/MoneyCell';
import BookingStatusCell from 'shared/Table/shared/BookingStatusCell';
import { BookingStatusCellClasses } from 'shared/Table/shared/BookingStatusCell/model';
import {
  BookingStatusOptions,
  CarrierOptions,
  OrderTypeOptions,
  SystemOptions,
  ContractTermOptions,
  BVoIPInterfaceTypeOptions,
} from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTable/FilterSelectOptions';
import formatDate from 'core/utilities/dateUtilities/formatDate';
// eslint-disable-next-line no-unused-vars
import { ITableCellBookingDataItem } from 'pages/Dashboard/Dashboard.models';
import {
  orderTypeLabels,
  bookingStatusLabels,
  bookingStatusClasses,
  carrierLabels,
  whosLoginLabels,
  termLabels,
  systemLabels,
  pbxInterfaceTypeLabels,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

export const columns = [
  {
    header: 'Booking Status',
    accessorKey: 'bookingStatus',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(BookingStatusOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }) => {
      if (!renderedCellValue) {
        return null;
      }

      const className = cx({
        [BookingStatusCellClasses.YELLOW]:
          renderedCellValue === bookingStatusClasses.PENDING_CUSTOMER_SIGNATURE,
        [BookingStatusCellClasses.ROSE]:
          renderedCellValue === bookingStatusClasses.TRANSACTIONAL,
        [BookingStatusCellClasses.ORANGE]:
          renderedCellValue === bookingStatusClasses.PENDING_ORDER_NUMBER,
        [BookingStatusCellClasses.DARK]:
          renderedCellValue === bookingStatusClasses.WORKING_ENGAGEMENT,
        [BookingStatusCellClasses.PURPLE]:
          renderedCellValue === bookingStatusClasses.PROJECT_BOOKED,
        [BookingStatusCellClasses.GREEN]:
          renderedCellValue === bookingStatusClasses.BOOKED,
      });

      return (
        <BookingStatusCell className={className as BookingStatusCellClasses}>
          {bookingStatusLabels[renderedCellValue as string]}
        </BookingStatusCell>
      );
    },
  },
  {
    header: 'Seller',
    accessorKey: 'seller',
    enableColumnFilter: true,
  },
  {
    header: 'Customer Name',
    accessorKey: 'customerName',
  },
  {
    header: 'Carrier (Master Agent)',
    accessorKey: 'carrier',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(CarrierOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      carrierLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Booking ID',
    accessorKey: 'bookingId',
  },
  {
    header: 'Order Type',
    accessorKey: 'orderType',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(OrderTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      orderTypeLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'MRC',
    accessorKey: 'mrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Signed',
    accessorKey: 'signed',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Projected EOM',
    accessorKey: 'projectEom',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Booked MRC',
    accessorKey: 'bookedMrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Booked NRC',
    accessorKey: 'bookedNrc',
    enableColumnFilter: false,
    Cell: MoneyCell,
  },
  {
    header: 'Opp ID',
    accessorKey: 'oppIds',
  },
  {
    header: 'Order Number',
    accessorKey: 'orderNumber',
  },
  {
    header: 'Location',
    accessorKey: 'location',
  },
  {
    header: 'Product',
    accessorKey: 'product',
  },
  {
    header: 'Bandwidth',
    accessorKey: 'bandwidth',
  },
  {
    header: 'System',
    accessorKey: 'system',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(SystemOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      systemLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Quantity',
    accessorKey: 'quantityOfNewBvoipTnsToReserve',
    muiTableHeadCellFilterTextFieldProps: {
      placeholder: 'Enter number',
      type: 'number',
    },
  },
  {
    header: 'Contract Term',
    accessorKey: 'term',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(ContractTermOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => {
      const value = Number(renderedCellValue);
      return renderedCellValue == null ? '' : termLabels[value];
    },
  },
  {
    header: 'BVoIP Interface Type',
    accessorKey: 'pbxInterfaceType',
    filterVariant: 'select',
    filterSelectOptions: convertDataFromFormToTable(BVoIPInterfaceTypeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      pbxInterfaceTypeLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Engagement Date',
    accessorKey: 'createdDatetime',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Booked Date',
    accessorKey: 'bookedDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Contract Request Date',
    accessorKey: 'contractRequestDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Contract Sent Date',
    accessorKey: 'contractSentDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Contract Countersigning Date',
    accessorKey: 'contractCountersigningDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'Order Handoff Date',
    accessorKey: 'orderHandoffDate',
    enableColumnFilter: false,
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      formatDate(renderedCellValue as string)),
  },
  {
    header: 'LOA Sent',
    accessorKey: 'loaSent',
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => (
      renderedCellValue ? 'Yes' : 'No'
    ),
  },
  {
    header: 'Contract Specialist',
    accessorKey: 'contractSpecialist',
  },
  {
    header: 'Who\'s Login',
    accessorKey: 'whosLogin',
    Cell: ({ renderedCellValue }: ITableCellBookingDataItem) => renderedCellValue && (
      whosLoginLabels[renderedCellValue as string]
    ),
  },
  {
    header: 'Sub-Agent Name',
    accessorKey: 'subAgentName',
  },
  {
    header: 'Existing Contract ID',
    accessorKey: 'existingContractId',
  },
  {
    header: 'Contract ID',
    accessorKey: 'contractId',
  },
  {
    header: 'Account Number',
    accessorKey: 'accountNumber',
  },
  {
    header: 'Circuit ID',
    accessorKey: 'circuitId',
  },
  {
    header: 'Notes',
    accessorKey: 'notes',
  },
] as ColumnDef<TRow>[];
