import React from 'react';
import { Box, Grid } from '@mui/material';
import Loader from 'shared/Loader';
import { useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { dynamicProductSchemaName } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsSowData/constants';
import FormikFieldsFromSchema from 'pages/WirelineEngagements/shared/FormikFieldsFromSchema';
import { controlledFieldsConfigs } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';

interface IEditRowSidebarFieldsProps {
  editRowId: string | number | undefined | null;
  isLoadingItem: boolean;
  isDisabled?: boolean;
}

const EditRowSidebarFields: React.FC<IEditRowSidebarFieldsProps> = ({
  editRowId, isLoadingItem, isDisabled,
}) => {
  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);
  const salesSowItem = useAppSelector((state) => state.wirelineEngagements.salesSowItem.result);

  const retrieveSchema = (
    parseOpenapiSchema(
      openApiSchemas,
      dynamicProductSchemaName,
    )
  );

  if (!editRowId) {
    return null;
  }

  if (isLoadingSchemas) {
    return (
      <Loader className="edit-row-sidebar__loader" />
    );
  }

  return (
    <Box className="edit-row-sidebar__fields">
      {isLoadingItem && <Loader />}
      <Grid
        container
        item
        rowSpacing={3}
      >
        <FormikFieldsFromSchema
          retrieveSchema={
            retrieveSchema.filter((schemaItem) => schemaItem.fieldName in salesSowItem)
          }
          controlledFieldsConfigs={controlledFieldsConfigs}
          isDisabled={isDisabled as boolean}
        />
      </Grid>
    </Box>
  );
};

EditRowSidebarFields.defaultProps = {
  isDisabled: false,
};

export default EditRowSidebarFields;
