import paths from 'core/routing/paths';
import { DASHBOARD_TABS, SELLER_DEPARTMENT_TABS } from 'pages/Dashboard/Dashboard.models';
import Wireline from 'pages/Dashboard/BusinessDirections/Wireline';
import EscalationSupport from 'pages/Dashboard/BusinessDirections/EscalationSupport';
import SellerDepartment from '@dashboardWirelineSellerDepartment/SellerDepartment';
import InsideSalesDepartment from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartment';
import ProjectManagerDepartment from '@dashboardWirelinePMDepartment/index';
import ContractSpecialistDepartment from '@dashboardWirelineContractSpecialistDepartment/index';
import BillingDepartment from '@dashboardWirelineBillingDepartment/BillingDepartment';
import { IRoutingConfigItem } from 'core/routing/models';
import RetentionManagerDepartment from '@dashboardWirelineRetentionDepartment/RetentionManagerDepartment';

export const dashboardWirelineRoutes = [
  {
    path: paths.dashboardWirelineSalesPath,
    label: 'Sales',
    component: SellerDepartment,
    id: SELLER_DEPARTMENT_TABS.WIRELINE_SALES,
  },
  {
    path: paths.dashboardWirelineInsideSalesPath,
    label: 'Inside Sales',
    component: InsideSalesDepartment,
    id: SELLER_DEPARTMENT_TABS.WIRELINE_INSIDE_SALES,
  },
  {
    path: paths.dashboardWirelineProjectManagerPath,
    label: 'Project Manager',
    component: ProjectManagerDepartment,
    id: SELLER_DEPARTMENT_TABS.WIRELINE_PROJECT_MANAGER,
  },
  {
    path: paths.dashboardWirelineContractSpecialistPath,
    label: 'Contract Specialist',
    component: ContractSpecialistDepartment,
    id: SELLER_DEPARTMENT_TABS.WIRELINE_CONTRACT_SPECIALIST,
  },
  {
    path: paths.dashboardWirelineBillingPath,
    label: 'Billing / Disconnect',
    component: BillingDepartment,
    id: SELLER_DEPARTMENT_TABS.WIRELINE_BILLING,
  },
  {
    path: paths.dashboardRetentionManagerPath,
    label: 'Retention Manager',
    component: RetentionManagerDepartment,
    id: SELLER_DEPARTMENT_TABS.WIRELINE_RETENTION,
  },
] as IRoutingConfigItem[];

export const dashboardRoutes = [
  {
    path: paths.dashboardWirelinePath,
    label: 'Wireline',
    component: Wireline,
    id: DASHBOARD_TABS.WIRELINE,
  },
  {
    path: paths.dashboardEscalationSupportPath,
    label: 'Escalation support',
    component: EscalationSupport,
    id: DASHBOARD_TABS.ESCALATION_SUPPORT,
  },
] as IRoutingConfigItem[];
