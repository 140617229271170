import React from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import {
  Grid, Typography, useTheme, useMediaQuery,
} from '@mui/material';
import { dateFormatWithTime } from 'core/constants';
import FormikTextareaField from 'shared/FormikField/FormikTextareaField';
import EditSaveControls from 'shared/EditSaveControls';
import {
  useWirelineEngagementsCreateContext,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import formatDatetime from 'core/utilities/dateUtilities/formatDatetime';
import EngagementsFormScopeDescriptionActions from '@EngagementsForm/EngagementsFormScopeDescription/EngagementsFormScopeDescriptionActions';
import { IEngagementsForm } from '@EngagementsForm/EngagementsForm.model';

const EngagementsFormScopeDescription = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { wirelineEngagementId } = useParams();
  const { submitForm, values, resetForm } = useFormikContext<IEngagementsForm>();
  const {
    isEditModeGeneral,
    isEditModeScopeDescription,
    setIsEditModeScopeDescription,
  } = useWirelineEngagementsCreateContext();

  const isCreateMode = !wirelineEngagementId;
  const isScopeDescriptionDisabled = !isEditModeScopeDescription || isCreateMode;

  const formattedModifiedDatetime = formatDatetime(
    values?.scopeDescription?.modifiedDatetime,
    dateFormatWithTime,
  );

  return (
    <Grid
      className="engagements-form__section"
      container
      direction="row"
      columnSpacing={5}
    >
      <Grid
        item
        container
        xs={12}
        pb={3}
      >
        <Grid
          item
          container
          direction="row"
          xs={12}
        >
          <Grid
            item
            container
            direction="row"
            md={4}
            xs={12}
          >
            <Grid
              item
              container
              direction="row"
              xs={12}
            >
              <Typography
                className="engagements-form__section-title"
                variant="body1"
                component="p"
              >
                Scope Description
              </Typography>
              <EditSaveControls
                onCancelEdit={resetForm}
                onSave={submitForm}
                isHidden={isCreateMode}
                isEditMode={isEditModeScopeDescription}
                setIsEditMode={setIsEditModeScopeDescription}
                isDisabled={isEditModeGeneral}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mb={1}
            >
              <Typography
                className="engagements-form__section-subtitle"
                variant="body1"
                component="p"
              >
                {formattedModifiedDatetime && `Edited: ${formattedModifiedDatetime}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            xs={8}
          >
            {!isTablet && <EngagementsFormScopeDescriptionActions />}
          </Grid>
          <Grid item mt={1} xs={12}>
            <FormikTextareaField
              fieldName="scopeDescription.text"
              disabled={isScopeDescriptionDisabled}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
          >
            {isTablet && <EngagementsFormScopeDescriptionActions />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EngagementsFormScopeDescription;
