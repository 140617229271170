import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import map from 'lodash/map';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import {
  editSowDataTitle,
  getSowItemMessage,
  patchSalesSowItemActionMessage, viewSowDataTitle,
} from '@EngagementsSowData/constants';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getSalesSowItemAction, patchSalesSowItemAction } from 'core/actions/Sales/SalesSowItem';
import { useTableContext } from 'shared/Table/TableContext';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebar';
import { controlledFieldsConfigs } from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields/constants';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import './EditRowSidebarForm.scss';

const EditRowSidebarForm = () => {
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const {
    activeRowMode, activeRowId, cleanActiveRow,
    setSecondarySidebarMode, secondarySidebarMode,
  } = useTableActiveRowContext<ISowDataListItem>();

  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);
  const salesSowItem = useAppSelector((state) => state.wirelineEngagements.salesSowItem.result);

  const isLoadingSowItem = useAppSelector(
    (state) => state.wirelineEngagements.salesSowItem.isLoading,
  );

  const onSubmit = (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    const formattedParams = cloneDeep(params);

    map(
      controlledFieldsConfigs,
      ({ fieldName, switchFieldName, switchEnabledValues }) => {
        const switchFieldValue = formattedParams[switchFieldName];

        const isDisabled = !switchEnabledValues.includes(switchFieldValue);

        if (isDisabled) {
          set(formattedParams, fieldName, get(salesSowItem, fieldName));
        }
      },
    );

    dispatch(patchSalesSowItemAction({ id: activeRowId, params: formattedParams }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        setSecondarySidebarMode(null);
        showSuccessNotifier(patchSalesSowItemActionMessage.success);
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(patchSalesSowItemActionMessage.error, e);
      });
  };

  useEffect(() => {
    if (!activeRowId || !(isViewMode || isEditMode)) return;

    dispatch(getSalesSowItemAction(activeRowId))
      .unwrap()
      .catch((e) => showErrorNotifier(getSowItemMessage.error, e));
  }, [activeRowId]);

  return (
    <Formik
      initialValues={salesSowItem}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <EditRowSidebar
          isViewMode={isViewMode}
          editRowId={(isEditMode || isViewMode) ? activeRowId : undefined}
          cleanEditRow={cleanActiveRow}
          title={isEditMode ? editSowDataTitle : viewSowDataTitle}
          isLoadingItem={isLoadingSowItem}
          setSecondarySidebarMode={setSecondarySidebarMode}
          secondarySidebarMode={secondarySidebarMode}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
