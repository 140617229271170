import React, { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, FormikProps } from 'formik';
import omit from 'lodash/omit';
import Loader from 'shared/Loader';
import { getEngagementsEditPath } from 'core/routing/paths';
import { getEngagementById, updateEngagementById } from 'core/api/wirelineEngagements.api';
import { IServerValidationError } from 'core/services/HTTPService.models';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { getFormInitialDataFromEngagement } from '@EngagementsForm/utilities';
import schema from '@EngagementsForm/EngagementsSchema';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import EngagementsFormFields from '@EngagementsForm/EngagementsFormFields';
import RouterFormConfirmDialogFormik from 'shared/RouterFormConfirmDialog/RouterFormConfirmDialogFormik';
import {
  createEngagementNotifications,
  reinitializeEngagementNotifications,
  updateEngagementNotifications,
  updateEngagementScopeDescriptionNotifications,
} from 'pages/WirelineEngagements/WirelineEngagementsCreate/constants';
import { IEngagement } from 'pages/WirelineEngagements/WirelineEngagements.models';

import './EngagementsForm.scss';

const EngagementsForm = () => {
  const ref = useRef<FormikProps<IEngagement>>(null);

  const formValues = ref.current?.values;

  const { wirelineEngagementId } = useParams();
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();
  const {
    draftId, engagementsInitialValues, isInitializing,
    isEditModeScopeDescription, isEditModeGeneral,
    setEngagementsInitialValues,
    setIsEditModeScopeDescription, setIsEditModeGeneral,
    newCompanyName,
  } = useWirelineEngagementsCreateContext();

  const navigate = useNavigate();

  const reinitializeForm = async (engagementId: number | string | undefined | null) => {
    if (!engagementId) return;

    setIsEditModeScopeDescription(false);
    setIsEditModeGeneral(false);

    try {
      const { data } = await getEngagementById(engagementId);
      setEngagementsInitialValues(data);
    } catch (e) {
      showErrorNotifier(reinitializeEngagementNotifications.error, e);
    }
  };

  const handleErrorSaveData = (error, { setErrors }, errorMessage) => {
    const definedError = error as IServerValidationError;
    setErrors(definedError?.response?.data);

    showErrorNotifier(errorMessage, error);
  };

  const onUpdateEngagementGeneral = async (params, formikHelpers) => {
    // should not send scopeDescription to update general fields (BE restriction)
    const engagementParams = omit(params, ['scopeDescription', 'selectedCustomer']);

    try {
      await updateEngagementById(wirelineEngagementId, engagementParams);
      showSuccessNotifier(updateEngagementNotifications.success);

      await reinitializeForm(wirelineEngagementId);
    } catch (error) {
      handleErrorSaveData(error, formikHelpers, updateEngagementNotifications.error);
    }
  };

  const onUpdateEngagementScopeDescription = async (params, formikHelpers) => {
    const isDescriptionChanged = params.scopeDescription.text
      !== engagementsInitialValues?.scopeDescription?.text;
    const engagementParams = omit(params, ['selectedCustomer']);

    if (!isDescriptionChanged) {
      return;
    }

    try {
      await updateEngagementById(wirelineEngagementId, engagementParams);
      showSuccessNotifier(updateEngagementScopeDescriptionNotifications.success);

      await reinitializeForm(wirelineEngagementId);
    } catch (error) {
      handleErrorSaveData(
        error,
        formikHelpers,
        updateEngagementScopeDescriptionNotifications.error,
      );
    }
  };

  const onCreateEngagement = async (params, formikHelpers) => {
    // should not send scopeDescription to create engagement (BE restriction)
    const engagementParams = omit(params, ['scopeDescription', 'selectedCustomer']);

    try {
      await updateEngagementById(draftId, engagementParams);
      showSuccessNotifier(createEngagementNotifications.success);

      await reinitializeForm(draftId);
      navigate(getEngagementsEditPath(draftId));
    } catch (error) {
      // @ts-ignore
      const errorMsg = error.response?.data?.nonFieldErrors?.[0]
        || createEngagementNotifications.error;

      handleErrorSaveData(error, formikHelpers, errorMsg);
    }
  };

  const onSubmit = async (params, formikHelpers) => {
    if (wirelineEngagementId && isEditModeGeneral) {
      await onUpdateEngagementGeneral(params, formikHelpers);
    } else if (wirelineEngagementId && isEditModeScopeDescription) {
      await onUpdateEngagementScopeDescription(params, formikHelpers);
    } else {
      await onCreateEngagement(params, formikHelpers);
    }
  };

  const initialValues = useMemo(() => (
    getFormInitialDataFromEngagement(engagementsInitialValues, newCompanyName, formValues)
  ), [engagementsInitialValues, newCompanyName]);

  if (isInitializing) {
    return <Loader />;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
      enableReinitialize
      innerRef={ref}
    >
      <Form>
        <EngagementsFormFields />
        <RouterFormConfirmDialogFormik />
      </Form>
    </Formik>
  );
};

export default EngagementsForm;
