import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch, useAppSelector } from 'core/store';
import { getSalesActiveNumbersItemAction, patchSalesActiveNumbersItemAction } from 'core/actions/Sales/SalesActiveNumbersItem';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import EditRowSidebar from 'pages/WirelineEngagements/shared/EditRowSidebar';
import {
  editActiveNumbersTitle,
  endpointToGetActiveNumbersSchemaName,
  getActiveNumbersItemNotifierMessage,
  patchActiveNumbersItemNotifierMessage, viewActiveNumbersTitle,
} from '@EngagementsActiveNumbers/constants';

import './EditRowSidebarForm.scss';

const EditRowSidebarForm = () => {
  const { showErrorNotifier, showSuccessNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const { getTableData } = useTableContext();

  const {
    activeRowId, cleanActiveRow, activeRowProduct, activeRowMode,
    secondarySidebarMode, setSecondarySidebarMode,
  } = useTableActiveRowContext();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const salesActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.result,
  );

  const isLoadingActiveNumbersItem = useAppSelector(
    (state) => state.wirelineEngagements.salesActiveNumbersItem.isLoading,
  );

  const onSubmit = (params, { setErrors, resetForm }) => {
    if (!activeRowId || !isEditMode) return;

    dispatch(patchSalesActiveNumbersItemAction({ id: activeRowId, params }))
      .unwrap()
      .then(() => {
        cleanActiveRow();
        showSuccessNotifier(patchActiveNumbersItemNotifierMessage.success);
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
        showErrorNotifier(patchActiveNumbersItemNotifierMessage.error, e);
      });
  };

  useEffect(() => {
    if (!activeRowId) return;

    dispatch(getSalesActiveNumbersItemAction(activeRowId))
      .unwrap()
      .catch((e) => showErrorNotifier(getActiveNumbersItemNotifierMessage.error, e));
  }, [activeRowId]);

  return (
    <Formik
      initialValues={salesActiveNumbersItem}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <EditRowSidebar
          isViewMode={isViewMode}
          editRowProduct={activeRowProduct}
          editRowId={(isEditMode || isViewMode) ? activeRowId : undefined}
          cleanEditRow={cleanActiveRow}
          title={isEditMode ? editActiveNumbersTitle : viewActiveNumbersTitle}
          endpointToGetSchemaName={endpointToGetActiveNumbersSchemaName}
          isLoadingItem={isLoadingActiveNumbersItem}
          secondarySidebarMode={secondarySidebarMode}
          setSecondarySidebarMode={setSecondarySidebarMode}
        />
      </Form>
    </Formik>
  );
};

export default EditRowSidebarForm;
