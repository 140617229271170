import {
  getCustomersListRequest,
  getCustomersListSuccess,
  getCustomersListFailure,
} from './actionsCreators';
import getCustomersList from './api.service';

const getCustomersListAction = (controller, pagination) => async (dispatch) => {
  try {
    const { signal } = controller;
    dispatch(getCustomersListRequest());
    const { data } = await getCustomersList(signal, pagination);
    dispatch(getCustomersListSuccess(data));
  } catch (error) {
    dispatch(getCustomersListFailure(error));
    throw error;
  }
};

export default getCustomersListAction;
