import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';

const calculatePeriod = (periodMapping, selectedPeriod): TPeriod => {
  const today = dayjs();

  const periodInfo = periodMapping[selectedPeriod] || { value: 0, unit: 'month' };
  const { value, unit } = periodInfo;

  const dateFrom = today.subtract(value, unit);

  return {
    dateFrom: dateFrom.format(dateFormat),
    dateTo: today.format(dateFormat),
  };
};

export default calculatePeriod;
