import React from 'react';
import dayjs from 'dayjs';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { TRow } from 'shared/Table/Table.models';
import convertDataFromFormToTable from 'core/utilities/convertDataFromFormToTable';
import { Status } from '@dashboardEscalationSupport/EscalationSupportTable/model';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import EscalationSupportStatus from '@dashboardEscalationSupport/EscalationSupportTable/EscalationSupportStatus';
import { userTeamRolesTeamDepartmentsLabels } from 'pages/UserManagement/constants';
import { dateFormat } from 'core/constants';

export const statusLabels = {
  [Status.NEW]: 'New',
  [Status.IN_PROGRESS]: 'In Progress',
  [Status.COMPLETED]: 'Completed',
};

export const statusOptions = [
  { value: Status.NEW, label: statusLabels[Status.NEW] },
  { value: Status.IN_PROGRESS, label: statusLabels[Status.IN_PROGRESS] },
  { value: Status.COMPLETED, label: statusLabels[Status.COMPLETED] },
];

export const typeOptions = [
  {
    value: UserTeamRoleTeamDepartment.PROJECT_MANAGER,
    label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.PROJECT_MANAGER],
  },
  {
    value: UserTeamRoleTeamDepartment.BILLING_SPECIALIST,
    label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.BILLING_SPECIALIST],
  },
  {
    value: UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST,
    label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.CONTRACT_SPECIALIST],
  },
  {
    value: UserTeamRoleTeamDepartment.SELLER,
    label: userTeamRolesTeamDepartmentsLabels[UserTeamRoleTeamDepartment.SELLER],
  },
];

export const columns = [
  {
    header: 'Customer Name',
    accessorKey: 'customerCompanyName',
  },
  {
    header: 'Escalation type',
    accessorKey: 'type',
    Cell: ({ renderedCellValue }) => (
      userTeamRolesTeamDepartmentsLabels[renderedCellValue as UserTeamRoleTeamDepartment]
      || renderedCellValue
    ),
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(typeOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select type' },
  },
  {
    header: 'Location',
    accessorKey: 'customerLocation',
  },
  {
    header: 'Product',
    accessorKey: 'product',
  },
  {
    header: 'Created By',
    accessorKey: 'creatorFullname',
  },
  {
    header: 'Assigned To',
    accessorKey: 'assigneeFullname',
  },
  {
    header: 'Status',
    accessorKey: 'status',
    filterVariant: 'multi-select',
    filterFn: 'equals',
    filterSelectOptions: convertDataFromFormToTable(statusOptions),
    muiTableHeadCellFilterTextFieldProps: { placeholder: 'Select status' },
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <EscalationSupportStatus escalationSupportStatus={renderedCellValue as Status} />
    ),
  },
  {
    header: 'Details',
    accessorKey: 'details',
  },
  {
    header: 'Created at',
    accessorKey: 'createdDatetime',
    minSize: 100,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue as string).format(dateFormat)
    ),
  },
] as ColumnDef<TRow>[];

export const productUrlParam = 'product';

export const customerCompanyNameUrlParam = 'customerCompanyName';

export const putEscalationSupportNotifierMessage = {
  success: 'Escalation Support item updated successfully.',
  error: 'Failed to update Escalation Support item.',
};

export const editEscalationSupportTitle = 'Edit Escalation Support';

export const getEscalationSupportItemNotifierMessage = {
  error: 'Failed to load Escalation Support item.',
};
