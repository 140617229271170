import React, { useCallback } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from 'core/store';
import CreateButton from 'shared/CreateButton';
import Table from 'shared/Table';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { TRow } from 'shared/Table/Table.models';
import { useNavigate } from 'react-router-dom';
import paths, { getEngagementsEditPath } from 'core/routing/paths';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import getEngagementsLisAction from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/GetEngagementsList/actions';
import {
  columns,
  createEngagementButtonText, createEngagementMobileButtonText,
  defaultVisibility,
  endpointToGetEngagementListSchemaName,
} from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/constants';
import { getColumnsFromOpenapiSchema } from 'core/utilities/openapiSchema/getColumnsFromOpenapiSchema';
import Loader from 'shared/Loader';

import './WirelineEngagementsList.scss';

const { CONTRACT_SPECIALIST, BILLING_SPECIALIST, RETENTION_MANAGER } = UserTeamRoleTeamDepartment;

const WirelineEngagementsList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const listData = useAppSelector((state) => state.wirelineEngagements.list);
  const isLoading = useAppSelector((state) => state.wirelineEngagements.list.isLoading);

  const isOpenapiSchemasLoading = useAppSelector((state) => state.openapiSchemas.isLoading);
  const schema = useAppSelector((state) => state.openapiSchemas.schemas);
  const openApiPaths = useAppSelector((state) => state.openapiSchemas.paths);

  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const isCreateButtonDisabled = isContractSpecialist || isBillingSpecialist || isRetentionManager;

  const onGoToCreateEngagement = () => {
    navigate(paths.wirelineEngagementsCreate);
  };

  const createEngagementAction = (
    <CreateButton
      className="user-engagement-btn"
      onClick={onGoToCreateEngagement}
      text={isMobile ? createEngagementMobileButtonText : createEngagementButtonText}
      disabled={isCreateButtonDisabled}
    />
  );

  const handleRowClick = useCallback((row) => {
    navigate(getEngagementsEditPath(row.original.id));
  }, [navigate]);

  const engagementColumns = getColumnsFromOpenapiSchema(
    schema,
    openApiPaths,
    endpointToGetEngagementListSchemaName,
    columns,
  );

  if (isOpenapiSchemasLoading) {
    return <Loader />;
  }

  return (
    <div className="engagement-list">
      <TableProvider
        id={tableIds.wirelineEngagements}
        columns={engagementColumns satisfies ColumnDef<TRow>[]}
        getDataAction={getEngagementsLisAction}
      >
        <Table
          isLoading={isLoading}
          rowCount={listData.count}
          toolBarAction={createEngagementAction}
          showColumnFilters
          showGlobalFilter
          rows={listData.results}
          handleRowClick={handleRowClick}
          defaultVisibility={defaultVisibility}
        />
      </TableProvider>
    </div>
  );
};

export default WirelineEngagementsList;
