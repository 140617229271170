import React from 'react';
import { useParams } from 'react-router-dom';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { firstPage } from 'shared/Table/constants';
import { useTableContext } from 'shared/Table/TableContext';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';
import CancelOrderModalForm from 'pages/WirelineEngagements/shared/CancelOrderModalForm';
import AddOrderModalForm from 'pages/WirelineEngagements/shared/AddOrderModalForm';
import EditRowSidebarForm from '@EngagementsSowData/WirelineEngagementsSowDataModals/EditRowSidebarForm';
import ActionsModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal';
import CommentsRowSidebar from 'pages/WirelineEngagements/shared/CommentsRowSidebar';
import { TableRowMode } from 'shared/Table/Table.models';

const WirelineEngagementsSowDataModals = ({
  isAddProductModalOpen, setIsAddProductModalOpen,
  isActionsModalOpen, setIsActionsModalOpen,
  orderAction, selectedOrders,
}) => {
  const { customerId } = useParams();
  const { setPagination, getTableData } = useTableContext();
  const {
    activeRow,
    activeRowId,
    activeRowMode,
    secondarySidebarMode,
    cleanActiveRow,
    setSecondarySidebarMode,
  } = useTableActiveRowContext<ISowDataListItem>();

  const { isEscalationSupportMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isCommentsInsideEditOpen = secondarySidebarMode === TableRowMode.COMMENT;

  const isActiveRow = isCommentMode || isCommentsInsideEditOpen;
  const commentsActiveRowId = (isActiveRow ? activeRowId : null) as string | number | null;

  const onAddProductSuccessCallback = () => {
    setPagination((paging) => ({
      ...paging,
      pageIndex: firstPage,
    }));

    getTableData();
  };

  return (
    <>
      <EditRowSidebarForm />
      <EscalationSupportModalForm
        escalationSupportRowId={isEscalationSupportMode ? activeRowId : undefined}
        escalationSupportRow={isEscalationSupportMode ? activeRow : undefined}
        cleanEscalationSupportRow={cleanActiveRow}
      />
      <CancelOrderModalForm
        activeRowMode={activeRowMode}
        activeRow={activeRow}
        cleanActiveRow={cleanActiveRow}
      />
      <AddOrderModalForm
        customerId={Number(customerId)}
        isAddProductModalOpen={isAddProductModalOpen}
        setIsAddProductModalOpen={setIsAddProductModalOpen}
        onSubmitSuccessCallback={onAddProductSuccessCallback}
      />
      <ActionsModal
        isModalOpen={isActionsModalOpen}
        setIsActionsModalOpen={setIsActionsModalOpen}
        orderAction={orderAction}
        selectedOrders={selectedOrders}
      />
      <CommentsRowSidebar
        title="Order Comments"
        activeRowId={commentsActiveRowId}
        isCommentsInsideEditOpen={isCommentsInsideEditOpen}
        setSecondarySidebarMode={setSecondarySidebarMode}
        cleanActiveRow={cleanActiveRow}
      />
    </>
  );
};

export default WirelineEngagementsSowDataModals;
