// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';

const createOrderNotifier = {
  success: 'Order created successfully',
  error: 'Failed to create order',
};

export enum fields {
  tsdId = 'tsdId',
  orders = 'orders',
  carrier = 'carrier',
  params = 'params',
  products = 'products',
  productId = 'productId',
  currentTSD = 'currentTSD',
  categoryId = 'categoryId',
  productName = 'productName',
  locationIds = 'locationIds',
  sellerRequestType = 'sellerRequestType',
  contractSupport = 'contractOrderSupport',
  projectManagementSupport = 'projectManagementSupport',
  customerAssistPm = 'customerAssistPm',
  billingSupport= 'billingSupport',
  customerAssistBilling = 'customerAssistBilling',
}

export const ATAndTLabel = 'AT&T';
export const nonATAndTCategoryLabel = 'Non AT&T';

export const sellerRequestTypeValues = {
  billingSuport: 'BILLING_SUPPORT',
  contractSupport: 'CONTRACT_SUPPORT',
  projectManagerSupport: 'PROJECT_MANAGER_SUPPORT',
};

export const sellerRequestTypeATTOptions: IOption[] = [
  {
    label: 'Contract/order support',
    value: sellerRequestTypeValues.contractSupport,
  },
  {
    label: 'Project Mannagement Support',
    value: sellerRequestTypeValues.projectManagerSupport,
  },
  {
    label: 'Billing Support',
    value: sellerRequestTypeValues.billingSuport,
  },
];

export const sellerRequestTypeNonATTOptions: IOption[] = [
  {
    label: 'Contract/order support',
    value: sellerRequestTypeValues.contractSupport,
  },
];

export const contractSuportValues = {
  new: 'NEW',
  reOrder: 'RE_ORDER',
  renewal: 'RENEWAL',
};

export const contractSuportOptions: IOption[] = [
  {
    label: 'new',
    value: contractSuportValues.new,
  },
  {
    label: 're-order',
    value: contractSuportValues.reOrder,
  },
  {
    label: 'renewal',
    value: contractSuportValues.renewal,
  },
];

export const projectManagerSupportValues = {
  orderProcessedByNetSpark: 'ORDER_PROCESSED_BY_NETSPARK',
  customerAssistPM: 'CUSTOMER_ASSIST_OR_PM',
};

export const projectManagerSupportOptions: IOption[] = [
  {
    label: 'Order processed by NetSpark',
    value: projectManagerSupportValues.orderProcessedByNetSpark,
  },
  {
    label: 'Customer Assist/PM',
    value: projectManagerSupportValues.customerAssistPM,
  },
];

export const billingSupportValues = {
  orderProcessedByNetSpark: 'ORDER_PROCESSED_BY_NETSPARK',
  customerAssistBilling: 'CUSTOMER_ASSIST_OR_BILLING',
};

export const billingSupportOptions: IOption[] = [
  {
    label: 'Order processed by NetSpark',
    value: billingSupportValues.orderProcessedByNetSpark,
  },
  {
    label: 'Customer Assist/Billing',
    value: billingSupportValues.customerAssistBilling,
  },
];

export const schemaSellerRequestType = {
  [sellerRequestTypeValues.contractSupport]: {
    type: 'select',
    fieldName: fields.contractSupport,
    placeholder: 'Contract/order support',
    options: contractSuportOptions,
  },
  [sellerRequestTypeValues.projectManagerSupport]: {
    type: 'select',
    fieldName: fields.projectManagementSupport,
    placeholder: 'Project Mannagement Support',
    options: projectManagerSupportOptions,
    nested: {
      [projectManagerSupportValues.customerAssistPM]: {
        type: 'text',
        fieldName: fields.customerAssistPm,
        placeholder: 'Customer Assist/PM',
      },
    },
  },
  [sellerRequestTypeValues.billingSuport]: {
    type: 'select',
    fieldName: fields.billingSupport,
    placeholder: 'Billing Support',
    options: billingSupportOptions,
    nested: {
      [billingSupportValues.customerAssistBilling]: {
        type: 'text',
        fieldName: fields.customerAssistBilling,
        placeholder: 'Customer Assist/Billing',
      },
    },
  },
};

export default createOrderNotifier;
