import {
  GET_CUSTOMERS_LIST_REQUEST,
  GET_CUSTOMERS_LIST_SUCCESS,
  GET_CUSTOMERS_LIST_FAILURE,
} from './actionsTypes';

export const getCustomersListRequest = () => ({ type: GET_CUSTOMERS_LIST_REQUEST });
export const getCustomersListSuccess = (data) => ({ type: GET_CUSTOMERS_LIST_SUCCESS, data });
export const getCustomersListFailure = (error) => ({
  type: GET_CUSTOMERS_LIST_FAILURE,
  error,
});
