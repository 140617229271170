import React from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import Loader from 'shared/Loader';
import EngagementsFormGeneral from './EngagementsFormGeneral';
import EngagementsFormScopeDescription from './EngagementsFormScopeDescription';

const EngagementsFormFields = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Box className="engagements-form__wrapper">
      {isSubmitting && <Loader />}
      <EngagementsFormGeneral />
      <EngagementsFormScopeDescription />
    </Box>
  );
};

export default EngagementsFormFields;
