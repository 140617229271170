import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { IOption } from 'shared/FormikField/FormikField.model';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import periodMapping from 'shared/PeriodAutocomplete/constants';

interface IPeriodAutocomplete {
  onChange: TSetPeriod,
  options: IOption[]
  defaultOption?: IOption | undefined
}

const PeriodAutocomplete: React.FC<IPeriodAutocomplete> = ({
  onChange,
  options,
  defaultOption,
}) => {
  const [value, setValue] = useState(defaultOption || options[0]);

  const onChangePeriod = (event, newValue) => {
    setValue(newValue);

    const newPeriod = calculatePeriod(periodMapping, newValue.value);
    onChange(newPeriod);
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label="Period"
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <EventIcon color="action" />
        ),
      }}
    />
  );

  return (
    <Autocomplete
      value={value}
      onChange={onChangePeriod}
      disableClearable
      data-testid="period-autocomplete"
      options={options}
      sx={{
        width: 155,
        '.MuiAutocomplete-input': {
          height: '10px',
        },
      }}
      renderInput={renderInput}
    />
  );
};

PeriodAutocomplete.defaultProps = {
  defaultOption: undefined,
};

export default PeriodAutocomplete;
