import React from 'react';
import { Formik, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import EscalationSupportModal from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupporModal';
import { EscalationSupportFormSchema } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupportFormSchema';
import postEscalationSupportAction from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupport';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';

interface IEscalationSupportModalFormProps {
  escalationSupportRow: IRenewalSowDataItem | ISowDataListItem | null | undefined
  escalationSupportRowId: string | number | null | undefined
  cleanEscalationSupportRow: () => void
  requestParams?: object
}

const EscalationSupportModalForm: React.FC<IEscalationSupportModalFormProps> = ({
  escalationSupportRowId, cleanEscalationSupportRow, escalationSupportRow, requestParams,
}) => {
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();

  const initialValues = EscalationSupportFormSchema.getDefault();

  const onSubmit = async (params, { setErrors, resetForm }) => {
    if (!escalationSupportRowId) return;

    await dispatch(postEscalationSupportAction({
      id: escalationSupportRowId,
      params: {
        ...params,
        ...requestParams,
      },
    }))
      .unwrap()
      .then(() => {
        cleanEscalationSupportRow();
        getTableData();
        resetForm();
      })
      .catch((e) => {
        setErrors(e?.response?.data);
      });
  };

  if (isEmpty(escalationSupportRow)) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={EscalationSupportFormSchema}
    >
      <Form>
        <EscalationSupportModal
          escalationSupportRowId={escalationSupportRowId}
          cleanEscalationSupportRow={cleanEscalationSupportRow}
          escalationSupportRow={escalationSupportRow}
        />
      </Form>
    </Formik>
  );
};

EscalationSupportModalForm.defaultProps = {
  requestParams: {},
};

export default EscalationSupportModalForm;
