import React from 'react';
import SellerDepartmentProvider from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';
import EngagementsMrcChart from '@dashboardWirelineSellerDepartment/EngagementsMrcChart';
import EngagementsTotalsChart from '@dashboardWirelineSellerDepartment/EngagementsTotalsChart';
import SellerFiltersByPeriod from 'pages/Dashboard/BusinessDirections/Wireline/WirelineDepartments/SellerDepartment/SellerFiltersByPeriod';
import EngagementsRevenueChart from '@dashboardWirelineSellerDepartment/EngagementsRevenueChart';
import EngagementsByPeriodChart from '@dashboardWirelineSellerDepartment/EngagementsByPeriodChart';
import TechnicalOverlay from '@dashboardWirelineSellerDepartment/TechnicalOverlay';
import BookingInfo from '@dashboardWirelineSellerDepartment/BookingInfo/BookingInfo';

import './SellerDepartment.scss';

const SellerDepartment = () => (
  <SellerDepartmentProvider>
    <div className="seller-department">
      <SellerFiltersByPeriod />
      <div className="seller-department__charts">
        <EngagementsTotalsChart />
        <EngagementsByPeriodChart />
        <EngagementsRevenueChart />
        <EngagementsMrcChart />
      </div>
      <TechnicalOverlay />
      <div className="seller-department__boking-info">
        <BookingInfo />
      </div>
    </div>
  </SellerDepartmentProvider>
);

export default SellerDepartment;
