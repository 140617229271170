import {
  GET_BOOKING_DATA_REQUEST,
  GET_BOOKING_DATA_SUCCESS,
  GET_BOOKING_DATA_FAILURE,
} from './actionsTypes';

export const getBookingDataRequest = () => (
  { type: GET_BOOKING_DATA_REQUEST }
);

export const getBookingDataSuccess = (data) => ({
  type: GET_BOOKING_DATA_SUCCESS,
  data,
});

export const getBookingDataFailure = (error) => ({
  type: GET_BOOKING_DATA_FAILURE,
  error,
});
