import React, { Dispatch, SetStateAction } from 'react';
import bookingInfoBookedOrderAction from '@dashboardWirelineSellerDepartment/BookingInfo/BookedOrder/BookedOrderAction';
import CustomModal from 'shared/CustomModal';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch } from 'core/store';
import { useTableContext } from 'shared/Table/TableContext';
import { IBookingDataItem } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingInfo.models';
import { bookedOrderNotifierMessage } from '@dashboardWirelineSellerDepartment/BookingInfo/BookedOrder/constants';

interface IBookedOrderModalProps {
  selectedBookedOrder: IBookingDataItem[],
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
}

const BookedOrderModal: React.FC<IBookedOrderModalProps> = ({
  selectedBookedOrder,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { getTableData, setRowSelection } = useTableContext();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const onBookedAccepted = async () => {
    try {
      await Promise.all(
        selectedBookedOrder.map(async ({ id }) => {
          try {
            await dispatch(bookingInfoBookedOrderAction(id)).unwrap();
            showSuccessNotifier(bookedOrderNotifierMessage.success);
          } catch {
            showErrorNotifier(bookedOrderNotifierMessage.error);
          }
        }),
      );
    } catch (error) {
      showErrorNotifier(error);
    }

    setRowSelection({});
    getTableData();
    setIsModalOpen(false);
  };

  return (
    <CustomModal
      title="Are you sure you want to book selected order/s?"
      isModalOpen={isModalOpen}
      confirmButtonText="Yes"
      cancelButtonText="No"
      onClickConfirm={onBookedAccepted}
      onCloseModal={setIsModalOpen}
      areButtonsVisible
    />
  );
};

export default BookedOrderModal;
