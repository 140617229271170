import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import FormikRadioGroup from 'shared/FormikField/FormikRadioGroup/FormikRadioGroup';
import { numericStatusOptions } from 'shared/constants';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import { ICustomerListItem } from 'pages/Customers/Customers.models';

const formatArrayIntoLine = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
};

type TFormik = { selectedCustomer: ICustomerListItem | null }

const EngagementsFormGeneralCustomerInformation = () => {
  const { values: { selectedCustomer } } = useFormikContext<TFormik>();

  if (!selectedCustomer) return null;

  return (
    <Grid
      item
      container
      direction="column"
      rowSpacing={3}
      md={6}
      xl={4}
      pb={3}
    >
      <FormikRadioGroup
        fieldName="selectedCustomer.status"
        options={numericStatusOptions}
        label="Customer Status"
        disabled
      />
      <FormikTextField
        fieldName="selectedCustomer.subAccountId"
        label="Sub-Account ID"
        variant="standard"
        disabled
      />
      <FormikTextField
        fieldName="selectedCustomer.netsparkReps"
        formatValue={formatArrayIntoLine}
        label="NetSpark Rep"
        variant="standard"
        disabled
      />
      <FormikTextField
        fieldName="selectedCustomer.contractSpecialists"
        formatValue={formatArrayIntoLine}
        label="Contract Specialist"
        variant="standard"
        disabled
      />
      <FormikTextField
        fieldName="selectedCustomer.projectManagers"
        formatValue={formatArrayIntoLine}
        label="Project Manager"
        variant="standard"
        disabled
      />
    </Grid>
  );
};

export default EngagementsFormGeneralCustomerInformation;
