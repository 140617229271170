import React from 'react';

import AddOrderModalFormContextWrap from 'pages/WirelineEngagements/WirelineEngagementsCreate/AddOrderModalFormContextWrap';
import CustomersCreateQuickForm from 'pages/Customers/CustomersCreate/CustomersCreateQuickForm';
import EngagementsForm from '@EngagementsForm/EngagementsForm';
import WirelineEngagementsCreateBackButton from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateBackButton';
import WirelineEngagementsCreateProvider from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';
import WirelineEngagementsWidgets from 'pages/WirelineEngagements/WirelineEngagementsWidgets';

const WirelineEngagementsCreate = () => (
  <WirelineEngagementsCreateProvider>
    <EngagementsForm />
    <WirelineEngagementsWidgets />
    <WirelineEngagementsCreateBackButton />
    <CustomersCreateQuickForm />
    <AddOrderModalFormContextWrap />
  </WirelineEngagementsCreateProvider>
);

export default WirelineEngagementsCreate;
