import React from 'react';

import { useTableContext } from 'shared/Table/TableContext';
import Table from 'shared/Table';
import { useSellerDepartmentContext } from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import { useAppSelector } from 'core/store';
import BookedOrderTopToolbar from '@dashboardWirelineSellerDepartment/BookingInfo/BookedOrder/BookedOrderTopToolbar';

const BookingTable = () => {
  const { getTableData } = useTableContext();
  const { period } = useSellerDepartmentContext();

  const isBookingDataLoading = useAppSelector((state) => state.dashboard.bookingData.isLoading);
  const bookingDataCount = useAppSelector((state) => state.dashboard.bookingData.count);
  const bookingData = useAppSelector((state) => state.dashboard.bookingData.results);

  useUpdateEffect(() => {
    getTableData();
  }, [period]);

  return (
    <>
      <BookedOrderTopToolbar />
      <Table
        isLoading={isBookingDataLoading}
        rowCount={bookingDataCount}
        showColumnFilters
        showGlobalFilter={false}
        rows={bookingData}
        showToolbar={false}
        enableStickyHeader
        muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
        enableSelectAll
      />
    </>
  );
};

export default BookingTable;
