import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import { ORDER_TYPE } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

interface IWirelineEngagementsActiveNumbersRowActionsProps {
  row: IActiveNumbersItem;
}

const { BILLING_SPECIALIST } = UserTeamRoleTeamDepartment;

const WirelineEngagementsActiveNumbersRowActions: React.FC<
  IWirelineEngagementsActiveNumbersRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,
    setActiveRowEdit,
    setActiveRowCancelOrder,
    setActiveRowView,
    setActiveRowComment,
  } = useTableActiveRowContext();
  const { isEditingAvailable, isCancelOrderAvailable } = useUserPermissions();

  const { isCancelOrderMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const rowId = get(row, 'id');
  const isCanceled = get(row, 'orderType') === ORDER_TYPE.CANCELED;

  const isCommentButtonSelected = isCommentMode && rowId === activeRowId;

  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isCancelOrderButtonSelected = isCancelOrderMode && rowId === activeRowId;
  const isCancelOrderButtonDisabled = (
    (!!activeRowId && !isCancelOrderButtonSelected)
    || isCanceled || isBillingSpecialist
  );
  const isCommentButtonDisabled = !!activeRowId && !isCommentButtonSelected;

  const isViewButton = isCanceled || !isEditingAvailable;
  const isMoreButtonDisabled = isCancelOrderButtonDisabled && isCommentButtonDisabled;

  const moreButtonConfig = compact([
    isCancelOrderAvailable && {
      onClick: () => setActiveRowCancelOrder(row),
      isDisabled: isCancelOrderButtonDisabled,
      icon: <ClearIcon />,
      text: 'Cancel Order',
    },
    {
      onClick: () => setActiveRowComment(row),
      isDisabled: isCommentButtonDisabled,
      icon: <CommentIcon />,
      text: 'Order Comments',
    },
  ]);

  return (
    <Actions<IActiveNumbersItem>
      row={row}
      activeRowId={activeRowId}
      isViewButton={isViewButton}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isMoreButtonDisabled={isMoreButtonDisabled}
      isActionButtonVisible={isEditingAvailable}
    />
  );
};

export default WirelineEngagementsActiveNumbersRowActions;
