import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { FileDownloadOutlined } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { downloadBlobFile } from 'core/utilities/downloadBlobFile';
import { ExportParams } from 'shared/ExportTableXlsxFile/ExportTableXlsxFile.models';
import { OnChangeStateFn } from 'core/models/general.models';
import { MRT_RowSelectionState as RowSelectionState } from 'material-react-table';
import { CircularProgress } from '@mui/material';

interface IExportTableXlsxFileProps {
  params: ExportParams;
  setRowSelection: OnChangeStateFn<RowSelectionState>;
  exportAction: (params: ExportParams)=> Promise<{ data: BlobPart }>;
  fileName: string;
}

const ExportTableXlsxFile: React.FC<IExportTableXlsxFileProps> = ({
  params, setRowSelection, exportAction, fileName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { showErrorNotifier } = useNotifiers();

  const isExportMenuOpen = Boolean(anchorEl);

  const openExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeExportMenu = () => {
    setAnchorEl(null);
  };

  const exportXlsxFile = async (exportParams: ExportParams) => {
    setIsLoading(true);
    closeExportMenu();

    try {
      const { data } = await exportAction(exportParams);

      downloadBlobFile(data, fileName);
    } catch (e) {
      showErrorNotifier(`Failed Export ${fileName}. Please try again.`, e);
    } finally {
      setIsLoading(false);

      if (params.additionalIds) {
        setRowSelection({});
      }
    }
  };

  const exportAllData = async () => {
    await exportXlsxFile({ id: params.id });
  };

  const exportSelectedData = async () => {
    await exportXlsxFile({
      id: params.id,
      additionalIds: params.additionalIds!,
    });
  };

  const exportPageData = async () => {
    await exportXlsxFile(params);
  };

  const exportText = isLoading ? <CircularProgress size={20} /> : <span>Export</span>;

  return (
    <div>
      <Button
        id="export-button"
        aria-controls={isExportMenuOpen ? 'export-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isExportMenuOpen ? 'true' : undefined}
        onClick={openExportMenu}
        startIcon={<FileDownloadOutlined />}
        disabled={isLoading}
        variant="outlined"
        sx={{ width: '108px' }}
      >
        {exportText}
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={isExportMenuOpen}
        onClose={closeExportMenu}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
      >
        <MenuItem onClick={exportAllData}>Export All Data</MenuItem>
        <MenuItem onClick={exportPageData}>Export Page</MenuItem>
        <MenuItem
          onClick={exportSelectedData}
          disabled={!params.additionalIds}
        >
          Export Selected
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ExportTableXlsxFile;
