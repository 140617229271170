import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import { useParams } from 'react-router-dom';
import Loader from 'shared/Loader';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';
import getUsersAction from 'core/actions/Customers/GetUsers/actions';
import getCustomerAction from 'core/actions/Customers/GetCustomerById/actions';
import CustomersCreateForm from 'pages/Customers/CustomersCreate/CustomersCreateForm/CustomersCreateForm';
import { getSellersParams } from 'pages/Customers/CustomersCreate/constants';

const CustomersEdit = () => {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();
  const customer = useAppSelector((state) => state.customers.customer.customerData);
  const isLoading = useAppSelector((state) => state.customers.customer.isLoading);
  const { seller } = customer;
  const sellersList = useAppSelector((state) => state.customers.users.results) || [];

  const sellersOptions: IOption[] = getOptionsFromUser(sellersList);

  useEffect(() => {
    dispatch(getCustomerAction(customerId));
    dispatch(getUsersAction(getSellersParams));
  }, []);

  if (!customerId) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CustomersCreateForm
      id={Number(customerId)}
      seller={seller}
      sellersOptions={sellersOptions}
      customer={customer}
    />
  );
};

export default CustomersEdit;
