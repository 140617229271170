import * as Yup from 'yup';
import { getOneOfFromType } from 'core/utilities/Yup/YupHelpers';
import { US_STATES } from 'core/models/states.models';

export const LocationFormSchema = Yup.object().shape({
  name: Yup
    .string()
    .default(''),
  address: Yup
    .string()
    .required('Address is required')
    .max(100, 'Should be no more than 100 digits')
    .default(''),
  alterAddress: Yup
    .string()
    .max(100, 'Should be no more than 100 digits')
    .default(''),
  city: Yup
    .string()
    .required('City is required')
    .max(50, 'Should be no more than 50 digits')
    .default(''),
  state: Yup
    .string()
    .oneOf(getOneOfFromType(US_STATES))
    .required('State is required')
    .default(''),
  zipCode: Yup
    .string()
    .matches(/^\d*$/, 'Should be a number')
    .max(5, 'Should be no more than 5 digits')
    .required('Zip code is required')
    .default(''),
  phoneNumber: Yup
    .string()
    .required('Phone number is required')
    .default(''),
});
