import {
  GET_CUSTOMERS_LIST_REQUEST,
  GET_CUSTOMERS_LIST_SUCCESS,
  GET_CUSTOMERS_LIST_FAILURE,
} from './actionsTypes';

const initialState = {
  isLoading: false,
  count: 0,
  results: [],
  errors: {},
};

const getCustomersListReducer = (state = initialState, {
  type, data, error,
}) => {
  switch (type) {
    case GET_CUSTOMERS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        results: [],
      };
    case GET_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        ...data,
        isLoading: false,
      };
    case GET_CUSTOMERS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        results: [],
        error,
      };
    default:
      return state;
  }
};

export default getCustomersListReducer;
