import React, { useMemo } from 'react';
import {
  Grid,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { LocationFormSchema } from 'shared/widgets/Locations/LocationForm/LocationFormSchema';
import { IServerValidationError } from 'core/services/HTTPService.models';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import { USStatesOptions } from 'core/models/USStatesConstants';
import { IDraftLocation } from 'shared/widgets/Locations/Locations.models';
import LocationFormButtons from 'shared/widgets/Locations/LocationForm/LocationFormButtons';

interface LocationFormProps {
  handleCancel: () => void;
  editLocation: (editLocation: IDraftLocation, id: number) => void;
  addNewLocation: (newLocation: IDraftLocation) => void;
  // eslint-disable-next-line no-restricted-globals
  location: IDraftLocation | null;
  switchToViewMode: ()=> void;
}

const LocationForm: React.FC<LocationFormProps> = ({
  handleCancel, location, editLocation, addNewLocation, switchToViewMode,
}) => {
  const onSubmit = async (params, { setErrors }) => {
    try {
      if (location && location?.id) {
        await editLocation(params, location.id);
      } else {
        await addNewLocation(params);
      }
      switchToViewMode();
    } catch (error) {
      const definedError = error as IServerValidationError;
      setErrors(definedError?.response?.data);
    }
  };
  const initialValues = useMemo(() => {
    const defaultValues = LocationFormSchema.getDefault();

    const locationWithoutNull = omitBy(location, isNil);

    return merge(defaultValues, locationWithoutNull);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LocationFormSchema}
      onSubmit={onSubmit}
    >
      <Form className="locations-form">
        <div data-testid="locations-form" className=" scroll">
          <Grid
            container
            rowSpacing={4}
          >
            <FormikTextField
              label="Location Name"
              fieldName="name"
              fullWidth
            />
            <FormikTextField
              label="Address 1"
              fieldName="address"
              required
              fullWidth
            />
            <FormikTextField
              label="Address 2"
              fieldName="alterAddress"
              fullWidth
            />
            <FormikTextField
              label="City"
              fieldName="city"
              required
              fullWidth
            />
            <FormikSelectField
              label="State"
              fieldName="state"
              options={USStatesOptions}
              required
              fullWidth
            />
            <FormikTextField
              label="Zip"
              fieldName="zipCode"
              required
              fullWidth
            />
            <FormikMobileMaskField
              label="Phone"
              fieldName="phoneNumber"
              required
              fullWidth
            />
          </Grid>
        </div>
        <LocationFormButtons
          handleCancel={handleCancel}
          isEditLocation={!!location?.id}
        />
      </Form>
    </Formik>
  );
};

export default LocationForm;
