import { getSalesSow } from 'core/api/sales.api';
import {
  getSalesSowRequest,
  getSalesSowSuccess,
  getSalesSowFailure,
} from 'core/actions/Sales/GetSalesSow/actionsCreators';

const getSalesSowAction = (
  controller: AbortController,
  params: {
    page: number;
    page_size: number;
    search?: string;
    ordering?: string | undefined;
  },
  customerIds: string[] | undefined = [],
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getSalesSowRequest());

    const combinedParams = {
      ...params,
      customerId: customerIds.join(', '),
    };

    const { data } = await getSalesSow(signal, combinedParams);

    dispatch(getSalesSowSuccess(data));
  } catch (error) {
    dispatch(getSalesSowFailure(error));

    throw error;
  }
};

export default getSalesSowAction;
