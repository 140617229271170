import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';
import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import DatePicker from 'shared/CustomRangePicker/DatePicker';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import './CustomRangepicker.scss';

interface ICustomRangePicker {
  onChange: TSetPeriod
  format?: string;
  defaultPeriod?: TPeriod
}

const CustomRangePicker: React.FC<ICustomRangePicker> = (
  { onChange, format, defaultPeriod },
) => {
  const [previousPeriod, setPreviousPeriod] = useState<TPeriod>(defaultPeriod as TPeriod);
  const [newPeriod, setNewPeriod] = useState<TPeriod>(defaultPeriod as TPeriod);

  const [isDateFromOpen, setIsDateFromOpen] = useState(false);
  const [isDateToOpen, setIsDateToOpen] = useState(false);

  const setNewDateFrom = (newValue) => {
    setNewPeriod({ ...newPeriod, dateFrom: dayjs(newValue).format(format) });
  };

  const setNewDateTo = (newValue) => {
    setNewPeriod({ ...newPeriod, dateTo: dayjs(newValue).format(format) });
  };

  const updatePickerPeriods = (updatedPeriod) => {
    setNewPeriod(updatedPeriod);
    setPreviousPeriod(updatedPeriod);
  };

  const onCloseDateToPicker = () => {
    const updatedPeriod = { dateFrom: newPeriod.dateFrom, dateTo: previousPeriod.dateTo };
    const isDateFromChange = previousPeriod.dateFrom !== newPeriod.dateFrom;

    if (isDateFromChange) {
      onChange(updatedPeriod);
    }
    updatePickerPeriods(updatedPeriod);

    setIsDateToOpen(false);
  };

  const onOpenDateToPicker = () => {
    setIsDateToOpen(true);
  };

  const onAcceptDateToPicker = () => {
    const isDateFromChange = newPeriod.dateFrom !== previousPeriod.dateFrom;
    const isDateToChange = newPeriod.dateTo !== previousPeriod.dateTo;

    if (isDateFromChange || isDateToChange) {
      const updatedPeriod = { dateFrom: newPeriod.dateFrom, dateTo: newPeriod.dateTo };

      updatePickerPeriods(updatedPeriod);
      onChange(updatedPeriod);
    }
    setIsDateToOpen(false);
  };

  const onOpenDateFromPicker = () => {
    setIsDateFromOpen(true);
  };

  const onCloseDateFromPicker = () => {
    setIsDateFromOpen(false);
    onOpenDateToPicker();
  };
  const onCancelDateFromPicker = () => {
    setNewDateFrom(previousPeriod.dateFrom);
    onCloseDateFromPicker();
  };

  useUpdateEffect(() => {
    if (defaultPeriod) {
      setNewPeriod(defaultPeriod);
      setPreviousPeriod(defaultPeriod);
    }
  }, [defaultPeriod]);

  return (
    <Box className="custom-range-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(newPeriod.dateFrom)}
          onChange={setNewDateFrom}
          minDate={dayjs(new Date()).subtract(1, 'year')}
          maxDate={dayjs(new Date())}
          onClose={onCloseDateFromPicker}
          open={isDateFromOpen}
          onOpen={onOpenDateFromPicker}
          onAccept={onCloseDateFromPicker}
          onCancel={onCancelDateFromPicker}
        />
        <HorizontalRuleIcon fontSize="medium" />
        <DatePicker
          value={dayjs(newPeriod.dateTo)}
          onChange={setNewDateTo}
          minDate={dayjs(newPeriod.dateFrom)}
          maxDate={dayjs(new Date())}
          onClose={onCloseDateToPicker}
          closeOnSelect={false}
          open={isDateToOpen}
          onOpen={onOpenDateToPicker}
          onAccept={onAcceptDateToPicker}
          onCancel={onCloseDateToPicker}
        />
      </LocalizationProvider>
    </Box>
  );
};

CustomRangePicker.defaultProps = {
  format: dateFormat,
  defaultPeriod: calculatePeriod(periodMapping, PERIOD_SELECT.THREE_MONTHS),
};

export default CustomRangePicker;
