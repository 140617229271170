import React from 'react';
import { useAppSelector } from 'core/store';
import { useNavigate } from 'react-router-dom';
import CreateButton from 'shared/CreateButton';
import paths, { getCustomersEditPath } from 'core/routing/paths';
import { columns, createCustomerButtonText } from 'pages/Customers/constans';
import Table from 'shared/Table';
import { TableProvider } from 'shared/Table/TableContext';
import { tableIds } from 'shared/Table/constants';
import { TRow } from 'shared/Table/Table.models';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import getCustomersListAction from './GetCustomersList/actions';

const CustomersList = () => {
  const listData = useAppSelector((state) => state.customers.list);
  const isLoading = useAppSelector((state) => state.customers.list.isLoading);
  const navigate = useNavigate();

  const createCustomerAction = (
    <CreateButton
      className="customer-create-btn"
      onClick={() => navigate(paths.customersCreate)}
      text={createCustomerButtonText}
    />
  );

  const handleRowClick = (row) => {
    navigate(getCustomersEditPath(row.original.id));
  };

  return (
    <div className="customers-list">
      <TableProvider
        id={tableIds.customers}
        getDataAction={getCustomersListAction}
        columns={columns as ColumnDef<TRow>[]}
      >
        <Table
          isLoading={isLoading}
          rowCount={listData.count}
          toolBarAction={createCustomerAction}
          handleRowClick={handleRowClick}
          showColumnFilters
          showGlobalFilter
          rows={listData.results}
        />
      </TableProvider>

    </div>
  );
};

export default CustomersList;
