import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import isNil from 'lodash/isNil';
import once from 'lodash/once';
import { TableRowMode } from 'shared/Table/Table.models';

interface IBasicRow { id?: string | number, product?: string }

interface ITableActiveRowContextProps<T> {
  activeRow: T | null
  activeRowId: string | number | null | undefined
  activeRowProduct: string | null | undefined
  activeRowMode: TableRowMode | null
  secondarySidebarMode: TableRowMode | null

  setActiveRowEdit: (row: T | null) => void
  setActiveRowEscalationSupport: (row: T | null) => void
  setActiveRowCancelOrder: (row: T | null) => void
  setActiveRowView: (row: T | null) => void
  setActiveRowComment: (row: T | null) => void

  cleanActiveRow: () => void
  setSecondarySidebarMode: (mode: TableRowMode | null)=> void;
}

const createTableActiveRowContext = once(
  <T extends IBasicRow> () => createContext({} as ITableActiveRowContextProps<T>),
);

export const TableActiveRowContextProvider = <T extends IBasicRow>({ children }) => {
  const TableActiveRowContext = createTableActiveRowContext<T>();

  const [activeRow, setActiveRow] = useState<T | null>(null);
  const [activeRowMode, setActiveRowMode] = useState<TableRowMode | null>(null);

  const [secondarySidebarMode, setSecondarySidebarMode] = useState<TableRowMode | null>(null);

  const cleanActiveRow = () => {
    setActiveRow(null);
    setActiveRowMode(null);
  };

  const setActiveRowWithState = (row: T | null, state: TableRowMode) => {
    if (isNil(row)) {
      cleanActiveRow();
      return;
    }

    setActiveRow(row);
    setActiveRowMode(state);
  };

  const setActiveRowEdit = (row: T | null) => {
    setActiveRowWithState(row, TableRowMode.EDIT);
  };

  const setActiveRowEscalationSupport = (row: T | null) => {
    setActiveRowWithState(row, TableRowMode.ESCALATION_SUPPORT);
  };

  const setActiveRowCancelOrder = (row: T | null) => {
    setActiveRowWithState(row, TableRowMode.CANCEL_ORDER);
  };

  const setActiveRowView = (row: T | null) => {
    setActiveRowWithState(row, TableRowMode.VIEW);
  };

  const setActiveRowComment = (row: T | null) => {
    setActiveRowWithState(row, TableRowMode.COMMENT);
  };

  const value = useMemo(() => ({
    activeRow,
    activeRowId: activeRow?.id as string | number | null | undefined,
    activeRowProduct: activeRow?.product as string | null | undefined,
    activeRowMode,

    setActiveRowEdit,
    setActiveRowEscalationSupport,
    setActiveRowCancelOrder,
    setActiveRowView,
    setActiveRowComment,

    secondarySidebarMode,
    setSecondarySidebarMode,

    cleanActiveRow,
  }), [activeRow, activeRowMode, secondarySidebarMode]);

  return (
    <TableActiveRowContext.Provider value={value}>
      {children}
    </TableActiveRowContext.Provider>
  );
};

export const useTableActiveRowContext = <T extends IBasicRow>() => (
  useContext(createTableActiveRowContext<T>())
);

export default TableActiveRowContextProvider;
