import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { Box } from '@mui/material';
import TabPanel from 'shared/TabPanel';
import styles from 'assets/styles/_resources.scss';
import TabComponent from './TabComponent';

const Tabs = ({
  tabItemsConfig, setDefaultTabIndex, defaultTabIndex, isScrollable,
}) => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0);
  const selectedNewTab = (event, newValue) => {
    setTabIndex(newValue);
    setDefaultTabIndex(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          value={tabIndex}
          onChange={selectedNewTab}
          className="tabs"
          data-testid="tabs"
          variant={isScrollable ? 'scrollable' : 'standard'}
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{ '.MuiTabs-indicator': { backgroundColor: `${styles.primaryColor}!important` } }}
        >
          {tabItemsConfig.map((item, i) => {
            const selected = tabIndex === i;
            return (
              <MuiTab
                key={item.name}
                component={React.forwardRef((props, ref) => (
                  <TabComponent
                    icon={item.icon}
                    title={item.name}
                    dataComponent={item.dataComponent}
                    onclick={() => setTabIndex(i)}
                    selected={selected}
                    disabled={item.disabled}
                    {...ref}
                    {...props}
                  />
                ))}
              />
            );
          })}
        </MuiTabs>
      </Box>
      {tabItemsConfig.map((item, index) => (
        <div key={item.name.toString()}>
          <TabPanel
            value={tabIndex}
            index={index}
            className="tabs__tab-panel"
          >
            {item.children || item.name}
          </TabPanel>
        </div>
      ))}
    </>
  );
};

Tabs.propTypes = {
  tabItemsConfig: PropTypes.instanceOf(Object).isRequired,
  setDefaultTabIndex: PropTypes.func,
  defaultTabIndex: PropTypes.number,
  isScrollable: PropTypes.bool,
};

Tabs.defaultProps = {
  setDefaultTabIndex: () => {},
  defaultTabIndex: 0,
  isScrollable: false,
};

export default Tabs;
