// eslint-disable-next-line no-unused-vars
import { TPeriodBooking } from 'shared/CustomRangePicker/CustomRangePicker.models';
import {
  getBookingDataRequest,
  getBookingDataSuccess,
  getBookingDataFailure,
} from './actionsCreators';

import getBookingData from './api.service';

const getBookingDataAction = (
  controller,
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    },
  period: TPeriodBooking | undefined) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getBookingDataRequest());

    const combinedParams = {
      ...params,
      ...period,
    };

    const { data } = await getBookingData(signal, combinedParams);
    dispatch(getBookingDataSuccess(data));
  } catch (error) {
    dispatch(getBookingDataFailure(error));
  }
};

export default getBookingDataAction;
