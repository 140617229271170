import find from 'lodash/find';
import { attLabel } from 'core/labels';
// eslint-disable-next-line no-unused-vars
import { ISalesSowMetaItem } from '@EngagementsSowData/salesSow.model';
// eslint-disable-next-line no-unused-vars
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
// eslint-disable-next-line no-unused-vars
import { TRow } from 'shared/Table/Table.models';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
// eslint-disable-next-line no-unused-vars
import { IOpenapiSchemasParsedItem } from 'core/models/openapi.models';
import { sellerRequestTypeValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { getConstantsFromEnumDescription } from 'core/utilities/openapiSchema';

export const getSowColumnsFromMeta = (
  metaColumns: ISalesSowMetaItem[],
  columns: ColumnDef<TRow>[],
  retrieveSchema: IOpenapiSchemasParsedItem[],
) => (
  metaColumns.map(({ label, name }) => {
    const column = find(columns, { accessorKey: name });
    const schemaField = find(retrieveSchema, { fieldName: name });
    const additionalParams: {Cell?: ColumnDef<TRow>['Cell']} = {};

    if (schemaField?.enum?.length && schemaField?.description && !column?.Cell) {
      const options = getConstantsFromEnumDescription(schemaField?.description);
      additionalParams.Cell = ({ renderedCellValue }) => (
        options[renderedCellValue as string] || renderedCellValue
      );
    }

    return {
      ...column,
      ...additionalParams,
      header: label,
      accessorKey: name,
      minSize: 100,
      enableColumnFilter: false,
      sortDescFirst: false,
    } as ColumnDef<TRow>;
  })
);

export const checkIfOrderNonAttTSD = (
  sowDataRowData: ISowDataListItem,
) => sowDataRowData.tsd !== attLabel;

export const checkIfOrdersIsAttTSD = (
  sowDataRowsData: ISowDataListItem[],
) => sowDataRowsData.some((item) => item.tsd !== attLabel);

export const checkIfOrdersRequestTypeIsNonCotractSupport = (
  sowDataRowsData: ISowDataListItem[],
) => sowDataRowsData.some((
  item,
) => item.sellerRequestType !== sellerRequestTypeValues.contractSupport);
