import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'core/store';
import { getOptionsFromUser } from 'core/utilities/getOptionsFromUser';
import Loader from 'shared/Loader';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import CustomersCreateForm from './CustomersCreateForm/CustomersCreateForm';

import './CustomersCreate.scss';
import useInitialFormData from './useInitialFormData';

const CustomersCreate = () => {
  const { id, seller, isLoading } = useInitialFormData();
  const sellersList = useAppSelector((state) => state.customers.users.results) || [];

  const sellersOptions: IOption[] = getOptionsFromUser(sellersList);

  if (isLoading) {
    return (
      <Box className="customers-create__loader-wrapper">
        <Loader />
      </Box>
    );
  }

  return (
    <CustomersCreateForm
      id={id}
      seller={seller}
      sellersOptions={sellersOptions}
    />
  );
};

export default CustomersCreate;
