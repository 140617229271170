import React from 'react';
import { useField } from 'formik';
// eslint-disable-next-line no-unused-vars
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormHelperText } from '@mui/material';
import FormikFieldWrapper from 'shared/FormikField/FormikFieldWrapper';
import { dateFormat } from 'pages/Dashboard/shared/FiltersByPeriod/constants';

interface IFormikDatePickerProps {
  fieldName: string
  label: string
  format?: string
  closeOnSelect?: boolean
  required?: boolean
  disabled?: boolean
}

const inputAdornment = {
  position: 'start' as 'start',
  sx: { marginRight: '0 !important' },
};

const actionBar = {
  actions: ['accept' as 'accept', 'clear' as 'clear'],
};

const FormikDatePicker: React.FC<IFormikDatePickerProps > = ({
  fieldName, format, label, required, closeOnSelect, disabled,
}) => {
  const [field, error, helpers] = useField(fieldName);

  const isError = Boolean(error.error && error.touched);
  const errorText = isError ? error.error : ' ';

  const selectedDate = field.value ? dayjs(field.value) : null;

  const onChange = (date: Dayjs | null) => {
    helpers.setValue(date ? date.format(format) : date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormikFieldWrapper
        label={label}
        fieldName={fieldName}
        required={required as boolean}
      >
        <MUIDatePicker
          {...field}
          value={selectedDate}
          onChange={onChange}
          format={format as string}
          closeOnSelect={closeOnSelect as boolean}
          disabled={disabled as boolean}
          slotProps={{
            inputAdornment,
            actionBar,
          }}
        />
      </FormikFieldWrapper>
      <FormHelperText error={isError}>
        {errorText}
      </FormHelperText>
    </LocalizationProvider>
  );
};

FormikDatePicker.defaultProps = {
  format: dateFormat,
  required: false,
  closeOnSelect: true,
  disabled: false,
};

export default FormikDatePicker;
