import React from 'react';
import { Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useParams } from 'react-router-dom';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { useWirelineEngagementsCreateContext } from 'pages/WirelineEngagements/WirelineEngagementsCreate/WirelineEngagementsCreateContext';

const { BILLING_SPECIALIST, CONTRACT_SPECIALIST, RETENTION_MANAGER } = UserTeamRoleTeamDepartment;

const AddProductButtonBlock = () => {
  const { wirelineEngagementId } = useParams();
  const {
    setIsAddProductModalOpen, isEditModeGeneral,
  } = useWirelineEngagementsCreateContext();

  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const isCreateMode = !wirelineEngagementId;
  const isAddProductButtonDisabled = isCreateMode || isEditModeGeneral
    || isBillingSpecialist || isContractSpecialist || isRetentionManager;

  const onOpenAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  return (
    <Grid
      item
      container
      direction="row"
    >
      <Button
        className="engagements-form__add-product"
        variant="contained"
        onClick={onOpenAddProductModal}
        disabled={isAddProductButtonDisabled}
      >
        <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
        <span>
          Add order
        </span>
      </Button>
    </Grid>
  );
};

export default AddProductButtonBlock;
