import React from 'react';
import { useAppDispatch } from 'core/store';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import CustomModal from 'shared/CustomModal';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import OrderItem from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal/OrderItem';
import requestQuoteAction from '@EngagementsSowData/RequestQuotes';
import {
  provideQuoteNotifier, requestQuoteNotifier,
  orderActionsTitle, provideContractNotifier, provideOrderNotifier,
} from '@EngagementsSowData/constants';
import provideQuoteAction from '@EngagementsSowData/ProvideQuote';
import provideContractAction from '@EngagementsSowData/ProvideContract';
import ProvideOrderAction from '@EngagementsSowData/ProvideOrder/ProvideOrder';
import { orderQuotesCondition } from '@EngagementsRenewalSowData/utils';

interface IActionsModalProps {
  isModalOpen: boolean;
  setIsActionsModalOpen: (isModalOpen: boolean) => void;
  orderAction: OrderActions;
  selectedOrders: ISowDataListItem[];
}

const ActionsModal: React.FC<IActionsModalProps> = ({
  isModalOpen,
  setIsActionsModalOpen,
  orderAction,
  selectedOrders,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData, setRowSelection } = useTableContext();

  const onRequestQuotes = async () => {
    const ordersWithoutQuoteRequest = selectedOrders.filter(
      (order) => !orderQuotesCondition(order),
    );

    await Promise.all(ordersWithoutQuoteRequest.map((order) => {
      const draftQuote = {
        orderId: order.id,
      };

      return dispatch(requestQuoteAction(draftQuote))
        .unwrap()
        .then(() => {
          showSuccessNotifier(requestQuoteNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(requestQuoteNotifier.error, e);
        });
    }));
  };

  const onProvideQuotes = async () => {
    const ordersWithoutQuoteProvide = selectedOrders.filter(
      (order) => !order.provideQuoteDatetime && order.quoteId);

    await Promise.all(ordersWithoutQuoteProvide.map(
      ({ quoteId }) => dispatch(provideQuoteAction(quoteId as string | number))
        .unwrap()
        .then(() => {
          showSuccessNotifier(provideQuoteNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(provideQuoteNotifier.error, e);
        })),
    );
  };

  const onProvideContracts = async () => {
    const ordersWithoutContractProvide = selectedOrders.filter(
      (order) => !order.provideContractDatetime && order.requestedContractId);

    await Promise.all(ordersWithoutContractProvide.map(
      ({ requestedContractId }) => dispatch(
        provideContractAction(requestedContractId as string | number))
        .unwrap()
        .then(() => {
          showSuccessNotifier(provideContractNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(provideContractNotifier.error, e);
        })),
    );
  };

  const onProvideOrders = async () => {
    const ordersWithRequestOrder = selectedOrders.filter(
      (order) => !order.provideOrderActionDatetime && order.orderActionId);

    await Promise.all(ordersWithRequestOrder.map(
      ({ orderActionId }) => dispatch(
        ProvideOrderAction(orderActionId as number))
        .then(() => {
          showSuccessNotifier(provideOrderNotifier.success);
        })
        .catch((e) => {
          showErrorNotifier(provideOrderNotifier.error, e);
        })),
    );
  };

  const orderActionsListActions = {
    [OrderActions.REQUEST_QUOTES]: onRequestQuotes,
    [OrderActions.PROVIDE_QUOTES]: onProvideQuotes,
    [OrderActions.PROVIDE_CONTRACTS]: onProvideContracts,
    [OrderActions.PROVIDE_ORDERS]: onProvideOrders,
  };

  const onClose = () => {
    setIsActionsModalOpen(false);
  };

  const onConfirm = async () => {
    await orderActionsListActions[orderAction]?.();

    setRowSelection({});
    getTableData();
    onClose();
  };

  const orderList = (
    <div>
      {selectedOrders.map((order) => (
        <OrderItem key={order.id} order={order} orderAction={orderAction} />
      ))}
    </div>
  );

  return (
    <CustomModal
      title={orderActionsTitle[orderAction]}
      confirmButtonText="Yes"
      cancelButtonText="No"
      isModalOpen={isModalOpen}
      onClickConfirm={onConfirm}
      onCloseModal={onClose}
      areButtonsVisible
    >
      {orderList}
    </CustomModal>
  );
};

export default ActionsModal;
