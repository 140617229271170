import {
  GET_ACTIVE_NUMBERS_REQUEST,
  GET_ACTIVE_NUMBERS_SUCCESS,
  GET_ACTIVE_NUMBERS_FAILURE,
} from 'core/actions/Sales/GetSalesActiveNumbers/actionsTypes';
import {
  IActiveNumbersItem,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers/activeNumbers.model';

interface IActiveNumbersState {
  isLoading: boolean,
  count: number,
  results: IActiveNumbersItem[],
  error: object | null,
}

const initialState: IActiveNumbersState = {
  isLoading: true,
  count: 0,
  results: [],
  error: {},
};

const getActiveNumbersReducer = (state = initialState, {
  type, data, error,
}) => {
  if (type === GET_ACTIVE_NUMBERS_REQUEST) {
    return {
      ...state,
      isLoading: true,
      results: [],
    };
  }

  if (type === GET_ACTIVE_NUMBERS_SUCCESS) {
    return {
      ...state,
      ...data,
      isLoading: false,
    };
  }

  if (type === GET_ACTIVE_NUMBERS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      results: [],
      error,
    };
  }

  return state;
};

export default getActiveNumbersReducer;
