import {
  BoolEnum, REQUEST_TYPE, StateEnum, ENGAGEMENT_BILING_TYPE,
} from '@EngagementsSowData/sowData.model';
// eslint-disable-next-line no-unused-vars
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

export const controlledFieldsConfigs: IControlledFieldsConfigItem[] = [
  {
    fieldName: 'migrationOfServiceComment',
    switchFieldName: 'migrationOfServiceStatus',
    switchEnabledValues: [BoolEnum.YES],
  },
  {
    fieldName: 'tnInventoryComment',
    switchFieldName: 'tnInventoryStatus',
    switchEnabledValues: [StateEnum.EXISTING],
  },
  {
    fieldName: 'creditRequest',
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.CREDIT_REQUEST],
  },
  {
    fieldName: 'customerSupport',
    switchFieldName: 'requestType',
    switchEnabledValues: [REQUEST_TYPE.CUSTOMER_SUPPORT],
  },
  {
    fieldName: 'etf',
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.EARLY_TERM_FREE],
  },
  {
    fieldName: 'gbq',
    switchFieldName: 'requestType',
    switchEnabledValues: [REQUEST_TYPE.GENERAL_BILLING_QUESTION],
  },
  {
    fieldName: 'salesSupport',
    switchFieldName: 'requestType',
    switchEnabledValues: [REQUEST_TYPE.SALES_SUPPORT],
  },
  {
    fieldName: 'other',
    switchFieldName: 'requestType',
    switchEnabledValues: [REQUEST_TYPE.OTHER],
  },
];
