import {
  GET_SALES_SOW_REQUEST,
  GET_SALES_SOW_SUCCESS,
  GET_SALES_SOW_FAILURE,
} from 'core/actions/Sales/GetSalesSow/actionsTypes';

export const getSalesSowRequest = () => ({ type: GET_SALES_SOW_REQUEST });

export const getSalesSowSuccess = (data) => ({
  type: GET_SALES_SOW_SUCCESS,
  data,
});

export const getSalesSowFailure = (error) => ({
  type: GET_SALES_SOW_FAILURE,
  error,
});
