import React from 'react';
import cx from 'classnames';
import { Status } from '@dashboardEscalationSupport/EscalationSupportTable/model';
import { statusLabels } from '@dashboardEscalationSupport/EscalationSupportTable/constants';

import './EscalationSupportStatus.scss';

interface IEscalationSupportStatus {
  escalationSupportStatus: Status;
}

const EscalationSupportStatus: React.FC<IEscalationSupportStatus> = (
  { escalationSupportStatus },
) => {
  const statusClassName = cx({
    new: escalationSupportStatus === Status.NEW,
    progress: escalationSupportStatus === Status.IN_PROGRESS,
    completed: escalationSupportStatus === Status.COMPLETED,
  });

  return (
    <div className={`escalation-support__status ${statusClassName}`}>
      {statusLabels[escalationSupportStatus]}
    </div>
  );
};

export default EscalationSupportStatus;
