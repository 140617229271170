import {
  GET_ACTIVE_NUMBERS_REQUEST,
  GET_ACTIVE_NUMBERS_SUCCESS,
  GET_ACTIVE_NUMBERS_FAILURE,
} from 'core/actions/Sales/GetSalesActiveNumbers/actionsTypes';

export const getSalesActiveNumbersRequest = () => ({ type: GET_ACTIVE_NUMBERS_REQUEST });

export const getSalesActiveNumbersSuccess = (data) => ({
  type: GET_ACTIVE_NUMBERS_SUCCESS,
  data,
});

export const getSalesActiveNumbersFailure = (error) => ({
  type: GET_ACTIVE_NUMBERS_FAILURE,
  error,
});
