import React from 'react';
import { IComment } from 'shared/widgets/Comments/Comments.models';
import CommentsListView from './CommentsListView';

interface ICommentsListProps {
    comments: IComment[]
    editCommentId: string | number
    editCommentText: string
    onFocusEditComment: (e: React.SyntheticEvent, comment: IComment) => void
    onEditComment: (e: React.SyntheticEvent) => void
    onRemoveComment: (e: React.SyntheticEvent, commentId: number) => void
}

const CommentsList: React.FC<ICommentsListProps> = ({
  comments,
  editCommentId,
  editCommentText,
  onFocusEditComment,
  onEditComment,
  onRemoveComment,
}) => (
  <div className="widget-comments__list">
    <CommentsListView
      comments={comments}
      editCommentId={editCommentId}
      editCommentText={editCommentText}
      onFocusEditComment={onFocusEditComment}
      onEditComment={onEditComment}
      onRemoveComment={onRemoveComment}
    />
  </div>
);

export default CommentsList;
