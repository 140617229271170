import React from 'react';
import { Typography } from '@mui/material';
import ActionsMenuItem from '@EngagementsSowData/SowDataActionsButton/ActionsMenuItem';
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import { SowDataActions } from '@EngagementsSowData/SowDataActionsButton/constsnts';

interface ISowDataActionsProvideProps {
  selectedOrders: ISowDataListItem[],
  handleClose: () => void,
  setOrderAction: (orderAction: OrderActions) => void,
  setIsActionsModalOpen: (isModalOpen: boolean) => void
}

const SowDataActionsProvide: React.FC<
  ISowDataActionsProvideProps
> = ({
  selectedOrders, handleClose, setOrderAction, setIsActionsModalOpen,
}) => {
  const allOrdersHaveProvideQuoteDatetime = selectedOrders.every(
    (order) => order.provideQuoteDatetime,
  );
  const someOrderHasEmptyQuotesProvideFields = selectedOrders.some((order) => !order.newServiceMrc);
  const isDisableQuotesProvide = allOrdersHaveProvideQuoteDatetime
  || someOrderHasEmptyQuotesProvideFields;

  const allOrdersHaveProvideContractDatetime = selectedOrders.every(
    (order) => order.provideContractDatetime,
  );
  const someOrderHasEmptyContractsProvideFields = selectedOrders.some(
    ({ newServiceContractId, contractSentDate }) => !newServiceContractId || !contractSentDate,
  );
  const isDisableContractsProvide = allOrdersHaveProvideContractDatetime
  || someOrderHasEmptyContractsProvideFields;

  const isDisabledProvideOrders = selectedOrders.some((
    {
      orderNumber,
      provideOrderActionDatetime,
      requestOrderActionDatetime,
      contractCountersigningDate,
    },
  ) => provideOrderActionDatetime
  || !requestOrderActionDatetime
  || !orderNumber
  || !contractCountersigningDate);

  const onProvideQuotes = async () => {
    handleClose();
    setOrderAction(OrderActions.PROVIDE_QUOTES);
    setIsActionsModalOpen(true);
  };

  const onProvideContracts = () => {
    handleClose();
    setOrderAction(OrderActions.PROVIDE_CONTRACTS);
    setIsActionsModalOpen(true);
  };

  const onProvideOrders = () => {
    handleClose();
    setOrderAction(OrderActions.PROVIDE_ORDERS);
    setIsActionsModalOpen(true);
  };

  return (
    <>
      <Typography
        className="actions-menu__title"
        variant="h6"
        component="h2"
      >
        Provide
      </Typography>
      <ActionsMenuItem
        onAction={onProvideQuotes}
        isDisable={isDisableQuotesProvide}
        content={SowDataActions.quotes}
      />
      <ActionsMenuItem
        onAction={onProvideContracts}
        isDisable={isDisableContractsProvide}
        content={SowDataActions.contracts}
      />
      <ActionsMenuItem
        onAction={onProvideOrders}
        isDisable={isDisabledProvideOrders}
        content={SowDataActions.orders}
      />
    </>
  );
};

export default SowDataActionsProvide;
