import React from 'react';
import { useFormikContext } from 'formik';
import CustomSidebar from 'shared/CustomSidebar';
import EditRowSidebarFields from 'pages/WirelineEngagements/shared/EditRowSidebar/EditRowSidebarFields';
import { getDrawerStyle, getOpenCommentsButton } from 'pages/WirelineEngagements/shared/utilites';
import { TableRowMode } from 'shared/Table/Table.models';
import './EditRowSidebar.scss';

interface IEditRowSidebarProps {
  editRowId: string | number | undefined | null
  cleanEditRow: () => void
  title: string
  isLoadingItem: boolean
  isViewMode?: boolean
  setSecondarySidebarMode: (mode: TableRowMode | null) => void
  secondarySidebarMode: TableRowMode | null
}

const EditRowSidebar: React.FC<IEditRowSidebarProps> = ({
  editRowId, cleanEditRow, title, isLoadingItem,
  isViewMode, setSecondarySidebarMode, secondarySidebarMode,
}) => {
  const { submitForm, setErrors, resetForm } = useFormikContext();

  const closeEditRowSidebar = () => {
    cleanEditRow();
    setErrors({});
    resetForm();
    setSecondarySidebarMode(null);
  };

  const openCommentsButton = getOpenCommentsButton(setSecondarySidebarMode);
  const drawerStyle = getDrawerStyle(!!secondarySidebarMode);

  return (
    <CustomSidebar
      isOpen={!!editRowId}
      onCloseSideBar={closeEditRowSidebar}
      title={title}
      onClickSave={submitForm}
      areButtonsVisible={!isViewMode as boolean}
      isConfirmDialog={!isViewMode as boolean}
      topAction={openCommentsButton}
      drawerStyle={drawerStyle}
    >
      <EditRowSidebarFields
        editRowId={editRowId}
        isLoadingItem={isLoadingItem}
        isDisabled={isViewMode as boolean}
      />
    </CustomSidebar>

  );
};

EditRowSidebar.defaultProps = {
  isViewMode: false,
};

export default EditRowSidebar;
