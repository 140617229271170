import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { useAppDispatch, useAppSelector } from 'core/store';
import getSalesSowAction from 'core/actions/Sales/GetSalesSow/actions';
import getSalesSowMeta from 'core/actions/Sales/GetSalesSowMeta/actions';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { TableProvider } from 'shared/Table/TableContext';
import { tableIds } from 'shared/Table/constants';
import ContentShrink from 'shared/ContentShrink';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import Loader from 'shared/Loader';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { getSowColumnsFromMeta } from '@EngagementsSowData/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import WirelineEngagementsSowDataTable from '@EngagementsSowData/WirelineEngagementsSowDataTable';
import { columns, dynamicProductSchemaName } from '@EngagementsSowData/constants';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { ISowDataListItem, ORDER_TYPE } from '@EngagementsSowData/sowData.model';
import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import { checkIfOrderNonAttTSD } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsSowData/utilities';

import './WirelineEngagementsSowData.scss';

const {
  RETENTION_MANAGER, BILLING_SPECIALIST, PROJECT_MANAGER, CONTRACT_SPECIALIST,
} = UserTeamRoleTeamDepartment;

const WirelineEngagementsSowDataContainer = () => {
  const dispatch = useAppDispatch();
  const { customerId } = useParams();

  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const metaColumns = useAppSelector((state) => state.wirelineEngagements.salesSowMeta.meta);
  const isLoadingMeta = useAppSelector((state) => state.wirelineEngagements.salesSowMeta.isLoading);
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = useMemo(() => parseOpenapiSchema(
    openApiSchemas,
    dynamicProductSchemaName,
  ), [openApiSchemas],
  );

  const availableColumns = useMemo(
    () => (metaColumns?.length
     && retrieveSchema?.length
      ? getSowColumnsFromMeta(metaColumns, columns, retrieveSchema) : []),
    [metaColumns, retrieveSchema],
  );

  const enableRowSelection = (
    !isRetentionManager && ((row) => {
      const isNotCancaled = get(row, 'original.orderType') !== ORDER_TYPE.CANCELED;
      const isRowNonAttTSD = checkIfOrderNonAttTSD(row.original);
      const isAttTSDForRoles = !((
        isBillingSpecialist
        || isProjectManager
        || isContractSpecialist
      ) && isRowNonAttTSD);

      return isNotCancaled && isAttTSDForRoles;
    })
  );

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<ISowDataListItem>();
  const { isEditMode, isViewMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isTableShrank = isEditMode || isViewMode || isCommentMode;

  useEffect(() => {
    (async () => {
      await dispatch(getSalesSowMeta());
    })();
  }, []);

  if (!customerId) {
    return null;
  }

  if (isLoadingMeta || isLoadingSchemas) {
    return <Loader />;
  }

  return (
    <div className="sow-data">
      <BackToEditEngagementButton title="SOW Data" />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<string[]>
          id={tableIds.salesSow}
          columns={availableColumns}
          getDataAction={getSalesSowAction}
          enableEditing={!isRetentionManager}
          enableRowSelection={enableRowSelection}
          requestParams={[customerId]}
        >
          <WirelineEngagementsSowDataTable />
        </TableProvider>
      </ContentShrink>
    </div>
  );
};

export default WirelineEngagementsSowDataContainer;
