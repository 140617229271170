import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import { useIsUserType } from 'core/hooks/useIsUserType';
import { useTableContext } from 'shared/Table/TableContext';
import Show from 'shared/Show';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem, OrderActions } from '@EngagementsSowData/sowData.model';
import SowDataActionsRequest from '@EngagementsSowData/SowDataActionsButton/SowDataActionsRequest';
import SowDataActionsProvide from '@EngagementsSowData/SowDataActionsButton/SowDataActionsProvide';
import EngagementAccepted from '@EngagementsSowData/EngagementAccepted';
import { checkIfOrdersIsAttTSD, checkIfOrdersRequestTypeIsNonCotractSupport } from '@EngagementsSowData/utilities';

import './SowDataActionsButton.scss';

interface ISowDataActionsButtonProps {
  selectedOrders: ISowDataListItem[],
  setIsActionsModalOpen: (isModalOpen: boolean) => void
  setOrderAction: (orderAction: OrderActions) => void
}

const {
  BILLING_SPECIALIST, PROJECT_MANAGER, SELLER, CONTRACT_SPECIALIST,
} = UserTeamRoleTeamDepartment;

const SowDataActionsButton: React.FC<ISowDataActionsButtonProps> = ({
  selectedOrders, setIsActionsModalOpen, setOrderAction,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const { rowSelection } = useTableContext();

  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);
  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const isUserAdmin = useIsUserType(UserType.ADMIN);
  const hasRowNonAttTSD = checkIfOrdersIsAttTSD(selectedOrders);
  const hasRowRequestTypeIsNonContractOrder = checkIfOrdersRequestTypeIsNonCotractSupport(
    selectedOrders,
  );

  const isActionsBtnDisabled = isBillingSpecialist
  || isProjectManager
  || hasRowNonAttTSD
  || hasRowRequestTypeIsNonContractOrder
  || isEmpty(rowSelection);
  const isRequestActionsHidden = isContractSpecialist;
  const isProvideActionsHidden = isSeller;

  const isEngagementAcceptedHidden = isContractSpecialist || isUserAdmin;

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Actions" placement="top">
        <span>
          <Button
            className="actions-btn"
            id="sow-data-actions-btn"
            aria-controls={isOpen ? 'sow-data-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            onClick={handleOpenMenu}
            disabled={isActionsBtnDisabled}
          >
            <MoreHorizIcon />
          </Button>
        </span>
      </Tooltip>
      <Menu
        className="actions-menu"
        id="sow-data-actions-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sow-data-actions-btn',
        }}
      >
        <Show on={!isRequestActionsHidden}>
          <SowDataActionsRequest
            selectedOrders={selectedOrders}
            handleClose={handleClose}
            setOrderAction={setOrderAction}
            setIsActionsModalOpen={setIsActionsModalOpen}
          />
        </Show>
        <Show on={!isProvideActionsHidden}>
          <SowDataActionsProvide
            selectedOrders={selectedOrders}
            handleClose={handleClose}
            setOrderAction={setOrderAction}
            setIsActionsModalOpen={setIsActionsModalOpen}
          />
        </Show>
      </Menu>
      {isEngagementAcceptedHidden && <EngagementAccepted />}
    </>
  );
};

export default SowDataActionsButton;
