import React, { useState } from 'react';
import { Button } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAppSelector } from 'core/store';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';
import { IBookingDataItem } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingInfo.models';
import BookedOrderModal from '@dashboardWirelineSellerDepartment/BookingInfo/BookedOrder/BookedOrderModal';

const BookedOrderButton = () => {
  const bookedOrderData = useAppSelector(
    (state) => state.dashboard.bookingData.results,
  );

  const selectedBookedOrder = useTableSelectedRows<IBookingDataItem>(
    { rows: bookedOrderData },
  );

  const [isBookedOrderModalOpen, setIsBookedOrderModalOpen] = useState(false);

  const onOpenBookedOrderModal = () => {
    setIsBookedOrderModalOpen(true);
  };

  return (
    <>
      <Button
        className="booked-order__button"
        disabled={isEmpty(selectedBookedOrder)}
        onClick={onOpenBookedOrderModal}
        data-testid="booked-order-button-test-id"
      >
        <CheckCircleOutlineIcon className="booked-order__button__icon" />
        Booked Order
      </Button>
      <BookedOrderModal
        selectedBookedOrder={selectedBookedOrder}
        isModalOpen={isBookedOrderModalOpen}
        setIsModalOpen={setIsBookedOrderModalOpen}
      />
    </>
  );
};

export default BookedOrderButton;
