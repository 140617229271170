import React from 'react';
import dayjs from 'dayjs';
import { dateFormat } from 'core/constants';
import ActiveNumbersStatus from '@EngagementsActiveNumbers/ActiveNumbersStatus';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import {
  portDescriptionLabels,
  providedBodyCellStyle,
  providedHeadCellStyle,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

export const activeNumbersTitle = 'Active Numbers';

export const columns = [
  {
    accessorKey: 'engagementAcceptedDate',
    minWidth: '100px',
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
    Cell: ({ renderedCellValue }) => renderedCellValue && (
    <RequestProvidedDate requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'portDescription',
    Cell: ({ renderedCellValue }) => (
      portDescriptionLabels[renderedCellValue] || renderedCellValue
    ),
  },
  {
    accessorKey: 'portOrVlanDate',
    minWidth: '100px',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue).format(dateFormat)
    ),
  },
  {
    accessorKey: 'status',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
    <ActiveNumbersStatus orderStatus={renderedCellValue} />
    ),
  },
  {
    accessorKey: 'ttuDate',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue).format(dateFormat)
    ),
  },
  {
    accessorKey: 'completionDate',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      dayjs(renderedCellValue).format(dateFormat)
    ),
  },
];

export const endpointToGetActiveNumbersListSchemaName = '/api/sales/active-numbers/';

export const editActiveNumbersTitle = 'Edit Active Numbers';
export const viewActiveNumbersTitle = 'View Active Numbers';

export const endpointToGetActiveNumbersSchemaName = '/api/sales/active-numbers/{id}/';

export const getActiveNumbersItemNotifierMessage = {
  error: 'Failed to load Active Numbers item.',
};

export const patchActiveNumbersItemNotifierMessage = {
  success: 'Active Numbers item updated successfully.',
  error: 'Failed to update Active Numbers item.',
};

export const engagementAcceptedDateKey = 'engagementAcceptedDate';
