import React, { useEffect, useState } from 'react';
import { HTTPService } from 'core/services';
import CustomSidebar from 'shared/CustomSidebar';
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  getSalesOrderCommentsListAction,
  clearCommentsAction,
  clearCommentsErrorsAction,
  createSalesOrderCommentAction,
  deleteSalesOrderCommentItemAction,
  editSalesOrderCommentItemAction,
} from 'core/actions/Sales/OrderComments';
import CommentsList from 'shared/widgets/Comments/CommentsList';
import CommentsTextArea from 'pages/WirelineEngagements/shared/CommentsRowSidebar/CommentsTextArea';
// eslint-disable-next-line no-unused-vars
import { TableRowMode } from 'shared/Table/Table.models';
import './CommentsRowSidebar.scss';

let controller = HTTPService.getController();
const setNewController = () => {
  controller = HTTPService.getController();
};
const cancelRequest = () => HTTPService.cancelRequest(controller);

interface ICommentsRowSidebarProps {
  activeRowId?: string | number | null;
  cleanActiveRow: () => void;
  isCommentsInsideEditOpen: boolean;
  setSecondarySidebarMode: (mode: TableRowMode | null) => void;
  title: string;
}

const CommentsRowSidebar: React.FC<ICommentsRowSidebarProps> = ({
  activeRowId, cleanActiveRow, title, isCommentsInsideEditOpen, setSecondarySidebarMode,
}) => {
  const dispatch = useAppDispatch();
  const {
    results, isLoading, error,
  } = useAppSelector((state) => state.wirelineEngagements.orderComments);

  const [newCommentText, setNewCommentText] = useState('');
  const [editCommentId, setEditCommentId] = useState('');
  const [editCommentText, setEditCommentText] = useState('');

  const onCloseCallback = () => {
    if (isCommentsInsideEditOpen) {
      setSecondarySidebarMode(null);
    } else {
      cleanActiveRow();
    }
  };

  const closeEditRowSidebar = () => {
    dispatch(clearCommentsAction());
    setNewCommentText('');
    setEditCommentId('');
    setEditCommentText('');

    onCloseCallback();
  };

  const onFocusEditComment = (e, comment) => {
    e.preventDefault();
    const { id } = comment;
    setEditCommentId(id);
    setEditCommentText(e.target.value);
    dispatch(clearCommentsErrorsAction());
  };

  const onEditComment = (e) => {
    setEditCommentText(e.target.value);
  };

  const onRemoveComment = (e, commentId) => {
    e.preventDefault();
    dispatch(deleteSalesOrderCommentItemAction({
      parentId: activeRowId as string | number,
      id: commentId,
    }));
  };

  const onChangeComment = (e) => {
    setNewCommentText(e.target.value);
    dispatch(clearCommentsErrorsAction());
  };

  const onCreateNewComment = (e) => {
    e.preventDefault();
    dispatch(createSalesOrderCommentAction({
      parentId: activeRowId as string | number,
      params: { text: newCommentText },
    }));
    setNewCommentText('');
  };

  const onUpdateComment = async (e) => {
    e.preventDefault();

    await dispatch(editSalesOrderCommentItemAction({
      parentId: activeRowId as string | number,
      id: Number(editCommentId),
      params: { text: editCommentText },
    })).unwrap();

    setEditCommentId('');
    setEditCommentText('');
  };

  useEffect(() => {
    if (!activeRowId) {
      cancelRequest();
      return;
    }

    setNewController();

    dispatch(getSalesOrderCommentsListAction({
      parentId: activeRowId, controller,
    }));

    return cancelRequest;
  }, [activeRowId]);

  return (
    <CustomSidebar
      className="comments-sidebar"
      isOpen={!!activeRowId}
      onCloseSideBar={closeEditRowSidebar}
      title={title}
      isLoading={isLoading}
      isConfirmDialog={false}
      customBottomBlock={(
        <CommentsTextArea
          newCommentText={newCommentText}
          error={error}
          editCommentId={editCommentId}
          onChangeComment={onChangeComment}
          onCreateNewComment={onCreateNewComment}
          onUpdateComment={onUpdateComment}
          onCancel={closeEditRowSidebar}
        />
      )}
    >
      <CommentsList
        comments={results}
        editCommentId={editCommentId}
        editCommentText={editCommentText}
        onFocusEditComment={onFocusEditComment}
        onEditComment={onEditComment}
        onRemoveComment={onRemoveComment}
      />
    </CustomSidebar>
  );
};

CommentsRowSidebar.defaultProps = {
  activeRowId: null,
};

export default CommentsRowSidebar;
