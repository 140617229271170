import get from 'lodash/get';
import schema from '@EngagementsForm/EngagementsSchema';
// eslint-disable-next-line no-unused-vars
import { IEngagement } from 'pages/WirelineEngagements/WirelineEngagements.models';

export const getFormInitialDataFromEngagement = (
  serverEngagement: IEngagement | null,
  companyName: string| undefined,
  formValues: IEngagement| undefined,
) => {
  const defaultValue = schema.getDefault();

  if (companyName && !serverEngagement) {
    return { ...formValues, companyName };
  }

  if (!serverEngagement) {
    return defaultValue;
  }

  return {
    engagementType: get(serverEngagement, 'engagementType', defaultValue.engagementType),
    companyName: serverEngagement?.customer?.companyName || companyName || defaultValue.companyName,
    selectedCustomer: serverEngagement?.customer,
    subAgentName: serverEngagement?.subAgentName || defaultValue.subAgentName,
    bdsSupport: serverEngagement?.bdsSupport || defaultValue.bdsSupport,
    directSeller: serverEngagement?.directSeller || defaultValue.directSeller,
    technicalOverlay: serverEngagement?.technicalOverlay?.email || defaultValue.technicalOverlay,
    scopeDescription: {
      modifiedDatetime: serverEngagement?.scopeDescription?.modifiedDatetime || undefined,
      text: serverEngagement?.scopeDescription?.text || defaultValue.scopeDescription.text,
    },
  };
};

export default getFormInitialDataFromEngagement;
