import React from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { IOpenapiSchemasParsedItem } from 'core/models/openapi.models';
import FormikFieldSelector from 'pages/WirelineEngagements/shared/FormikFieldSelector';
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

interface IFormikFieldsFromSchemaProps {
  retrieveSchema: IOpenapiSchemasParsedItem[]
  controlledFieldsConfigs?: IControlledFieldsConfigItem[]
  isDisabled?: boolean
  isTooltip?: boolean
}

const FormikFieldsFromSchema: React.FC<
  IFormikFieldsFromSchemaProps
> = ({
  retrieveSchema, controlledFieldsConfigs, isDisabled, isTooltip,
}) => {
  if (isEmpty(retrieveSchema)) {
    return null;
  }

  return (
    <>
      {
        map(retrieveSchema, (item) => {
          const {
            properties,
            title,
            fieldName,
            enum: schemaEnum,
            description,
            format,
            readOnly,
            nullable,
            writeOnly,
          } = item;

          const isFieldDisabled = !!readOnly || isDisabled as boolean;

          if (isEmpty(properties)) {
            return (
              <FormikFieldSelector
                key={fieldName}
                controlledFieldsConfigs={controlledFieldsConfigs as IControlledFieldsConfigItem[]}
                fieldName={fieldName}
                label={title}
                schemaEnum={schemaEnum}
                description={description}
                format={format}
                disabled={isFieldDisabled}
                isTooltip={isTooltip as boolean}
                nullable={!!nullable}
                writeOnly={!!writeOnly}
              />
            );
          }

          return map(properties, (property) => {
            const {
              title: propertyTitle,
              fieldName: propertyFieldName,
              enum: propertySchemaEnum,
              description: propertyDescription,
              format: propertyFormat,
              readOnly: propertyReadOnly,
              nullable: propertyNullable,
              writeOnly: propertyWriteOnly,
            } = property;

            const isPropertyFieldDisabled = !!propertyReadOnly || isDisabled as boolean;

            return (
              <FormikFieldSelector
                key={`${fieldName}.${propertyFieldName}`}
                controlledFieldsConfigs={controlledFieldsConfigs as IControlledFieldsConfigItem[]}
                fieldName={`${fieldName}.${propertyFieldName}`}
                label={`${title} ${propertyTitle}`}
                schemaEnum={propertySchemaEnum}
                description={propertyDescription}
                format={propertyFormat}
                disabled={isPropertyFieldDisabled}
                isTooltip={isTooltip as boolean}
                nullable={!!propertyNullable}
                writeOnly={!!propertyWriteOnly}
              />
            );
          });
        })
      }
    </>
  );
};

FormikFieldsFromSchema.defaultProps = {
  controlledFieldsConfigs: [],
  isDisabled: false,
  isTooltip: false,
};

export default FormikFieldsFromSchema;
