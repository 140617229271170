import {
  GET_SALES_SOW_META_REQUEST,
  GET_SALES_SOW_META_SUCCESS,
  GET_SALES_SOW_META_FAILURE,
} from 'core/actions/Sales/GetSalesSowMeta/actionsTypes';

export const getSalesSowMetaRequest = () => ({ type: GET_SALES_SOW_META_REQUEST });

export const getSalesSowMetaSuccess = (data) => ({
  type: GET_SALES_SOW_META_SUCCESS,
  data,
});

export const getSalesSowMetaFailure = (error) => ({
  type: GET_SALES_SOW_META_FAILURE,
  error,
});
