import { isNull, isEmpty } from 'lodash';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';

export function orderQuotesCondition(order: ISowDataListItem) {
  const bandwidthCondition = 'bandwidth' in order && isEmpty(order.bandwidth);

  const isServiceAndContractIdFieldsExist = 'existingService' in order && 'existingContractId' in order;

  const condition1 = isNull(order.existingService) && isNull(order.existingContractId);
  const condition2 = order.existingService?.toLowerCase() === 'yes' && isNull(order.existingContractId);

  return (
    bandwidthCondition
    || (isServiceAndContractIdFieldsExist && (condition1 || condition2))
  );
}
