import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import { UserTeamRoleName, UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';

import { useIsUserType } from 'core/hooks/useIsUserType';

const { PROJECT_MANAGER, RETENTION_MANAGER } = UserTeamRoleTeamDepartment;

export const useUserPermission = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);

  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isDepartmentHead = useIsUserTeamRoleName(UserTeamRoleName.DEPARTMENT_HEAD);

  const isRetentionManager = useIsTeamRolesDepartmentMember(RETENTION_MANAGER);

  const isProjectManagerDepartmentHead = isProjectManager && isDepartmentHead;

  const isRenewalSowDataAvailable = isProjectManagerDepartmentHead || isAdmin || isRetentionManager;

  return {
    isRenewalSowDataAvailable,
  };
};
