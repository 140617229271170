import {
  ITeamRole, IUser, IUserExtended, IUserListItem,
} from 'pages/UserManagement/UserManagement.models';
import { netsparkAxios } from 'core/services/HTTPService';
import { IRequestPaging } from 'core/services/HTTPService.models';
import { teamRolesPath, usersPath } from 'core/api/constants';

export const getUsers = (params) => netsparkAxios.get<
  IRequestPaging<IUserListItem>
>(usersPath, { params });

export const getUserById = (id) => netsparkAxios.get<IUserExtended>(`${usersPath}${id}/`);

export const createUser = (params) => netsparkAxios.post<IUser>(usersPath, params);

export const updateUserById = (id, params) => netsparkAxios.put<IUser>(`${usersPath}${id}/`, params);

export const createTeamRole = (params: ITeamRole) => (
  netsparkAxios.post<ITeamRole>(teamRolesPath, params)
);

export const updateTeamRoleById = (id: string | number, params: ITeamRole) => (
  netsparkAxios.put<ITeamRole>(`${teamRolesPath}${id}/`, params)
);
