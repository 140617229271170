import React, { useEffect, useState } from 'react';
import { getLocationsByCustomerId } from 'core/api/customer.api';
import { Autocomplete, TextField } from '@mui/material';
import { getOptionsFromLocations } from 'core/utilities/getOptionsFromLocations';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { IOption } from 'shared/FormikField/FormikField.model';

import './LocationAutocomplete.scss';

interface ILocationAutocompleteProps {
  onChangeLocation: (locationId: number) => void;
  customerId: string| number | undefined;
}

const LocationAutocomplete: React.FC<ILocationAutocompleteProps> = (
  { onChangeLocation, customerId, ...props },
) => {
  const { showErrorNotifier } = useNotifiers();

  const [locations, setLocations] = useState<IOption[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<IOption | null>(null);

  if (!customerId) {
    return null;
  }

  const getLocations = async () => {
    try {
      const { data: locationsList } = await getLocationsByCustomerId(customerId);

      const options = getOptionsFromLocations(locationsList);

      setLocations(options);
      setSelectedLocation(options[0] || null);

      onChangeLocation(locationsList[0].id);
    } catch (e) {
      showErrorNotifier('Failed to load locations', e);
    }
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const onChange = (event, newValue) => {
    const newLocationId = newValue?.value ?? null;

    onChangeLocation(newLocationId);

    if (newValue) {
      setSelectedLocation(newValue);
    } else {
      setSelectedLocation(null);
      setInputValue('');
    }
  };

  const renderInput = (params) => <TextField {...params} label="Locations" />;

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <div className="location-autocomplete">
      <Autocomplete
        value={selectedLocation}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        options={locations}
        renderInput={renderInput}
        autoComplete={false}
        selectOnFocus={false}
        fullWidth
        data-testid="location-autocomplete"
        {...props}
      />
    </div>
  );
};

export default LocationAutocomplete;
