import React, { useState } from 'react';
import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTableContext } from 'shared/Table/TableContext';
import EngagementAcceptedModal from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/EngagementAcceptedModal';
import isEmpty from 'lodash/isEmpty';
import { engagementAcceptedTitle } from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted/constants';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { useAppSelector } from 'core/store';
import { useTableSelectedRows } from 'shared/Table/hooks/useTableSelectedRows';

const ActiveNumbersEngagementAccepted = () => {
  const { setRowSelection } = useTableContext();

  const [isEngagementAcceptedModalOpen, setIsEngagementAcceptedModalOpen] = useState(false);

  const activeNumbersData = useAppSelector(
    (state) => state.wirelineEngagements.activeNumbers.results,
  );

  const selectedActiveNumbers = useTableSelectedRows<IActiveNumbersItem>(
    { rows: activeNumbersData },
  );

  const onOpenEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(true);
  };

  const onCloseEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(false);
    setRowSelection({});
  };

  return (
    <div className="active-number__engagement-acceptance">
      <Button
        startIcon={<CheckCircleOutlineIcon />}
        disabled={isEmpty(selectedActiveNumbers)}
        onClick={onOpenEngagementAcceptedModal}
      >
        {engagementAcceptedTitle}
      </Button>
      <EngagementAcceptedModal
        isModalOpen={isEngagementAcceptedModalOpen}
        onCloseModal={onCloseEngagementAcceptedModal}
        selectedActiveNumbers={selectedActiveNumbers}
      />
    </div>
  );
};

export default ActiveNumbersEngagementAccepted;
