import React, { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import Table from 'shared/Table';
import { useAppSelector } from 'core/store';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import LocationAutocomplete from 'shared/LocationAutocomplete';
import { useTableContext } from 'shared/Table/TableContext';
import { colDefOptionActionsSingleIcon, colDefOptionActionsTwoIcons, colDefOptionSelect } from 'shared/Table/constants';
import { useUserPermissions } from '@EngagementsActiveNumbers/useUserPermissions';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import ActiveNumbersEngagementAccepted from '@EngagementsActiveNumbers/ActiveNumbersEngagementAccepted';
import { defaultColumnPinning, muiTableContainerProps } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import WirelineEngagementsActiveNumbersRowActions
  from '@EngagementsActiveNumbers/WirelineEngagementsActiveNumbersRowActions';
import ActiveNumbersTableModals from '@EngagementsActiveNumbers/ActiveNumbersTable/ActiveNumbersTableModals';

interface IActiveNumbersTableProps {
  setLocationId: Dispatch<SetStateAction<number | null>>
  locationId: number | null;
}

const ActiveNumbersTable: React.FC<
  IActiveNumbersTableProps
> = ({ setLocationId, locationId }) => {
  const isActiveNumbersLoading = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.isLoading);

  const activeNumbersData = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.results);

  const activeNumbersCount = useAppSelector((
    state) => state.wirelineEngagements.activeNumbers.count);

  const { getTableData } = useTableContext();

  const { customerId } = useParams();

  const { isLocationSearchUnavailable, isEditingAvailable } = useUserPermissions();

  const toolBarCustomSearch = (
    <LocationAutocomplete
      onChangeLocation={setLocationId}
      customerId={customerId}
    />
  );

  useUpdateEffect(() => {
    getTableData();
  }, [locationId]);

  return (
    <>
      <Table
        className="wireline-table"
        isLoading={isActiveNumbersLoading}
        rowCount={activeNumbersCount}
        showColumnFilters={false}
        showGlobalFilter={false}
        rows={activeNumbersData}
        enableSelectAll={false}
        toolBarCustomSearch={toolBarCustomSearch}
        defaultVisibility={{ id: false, orderType: false }}
        actionsButton={<ActiveNumbersEngagementAccepted />}
        showToolbar={!isLocationSearchUnavailable}
        initialColumnPinning={defaultColumnPinning}
        enableStickyHeader
        muiTableContainerProps={muiTableContainerProps}
        renderRowActions={({ row: { original } }) => (
          <WirelineEngagementsActiveNumbersRowActions
            row={original as unknown as IActiveNumbersItem}
          />
        )}
        displayColumnDefOptions={{
          ...colDefOptionSelect,
          ...(isEditingAvailable ? colDefOptionActionsTwoIcons : colDefOptionActionsSingleIcon),
        }}
      />
      <ActiveNumbersTableModals />
    </>
  );
};

export default ActiveNumbersTable;
