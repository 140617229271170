import { getActiveNumbers } from 'core/api/sales.api';
import {
  getSalesActiveNumbersRequest,
  getSalesActiveNumbersSuccess,
  getSalesActiveNumbersFailure,
} from 'core/actions/Sales/GetSalesActiveNumbers/actionsCreators';
import {
  // eslint-disable-next-line no-unused-vars
  IRequestParams,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsActiveNumbers/activeNumbers.model';

const getSalesActiveNumbersAction = (
  controller: AbortController,
  params: {
    page: number;
    page_size: number;
    search: string;
    ordering?: string | undefined;
  },
  additionalParams: IRequestParams | undefined,
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getSalesActiveNumbersRequest());

    const combinedParams = {
      ...params,
      customerId: additionalParams?.customerId,
      locationId: additionalParams?.locationId,
    };

    const { data } = await getActiveNumbers(signal, combinedParams);

    dispatch(getSalesActiveNumbersSuccess(data));
  } catch (error) {
    dispatch(getSalesActiveNumbersFailure(error));

    throw error;
  }
};

export default getSalesActiveNumbersAction;
