import React from 'react';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import getInsideSalesAction from 'core/actions/crm/InsideSales/GetInsideSalesList/actions';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { TRow } from 'shared/Table/Table.models';
import { TableActiveRowContextProvider } from 'shared/Table/TableActiveRowContext';
import InsideSalesListTable from 'pages/InsideSales/InsideSalesList/InsideSalesListTable';
import { columns } from 'pages/InsideSales/InsideSalesList/constants';
import useInsideSalesListPermissions from 'pages/InsideSales/InsideSalesList/useInsideSalesListPermissions';
import { EngagementType } from 'pages/InsideSales/model';

const InsideSalesList = () => {
  const {
    isEditEnabled,
    isRequestAcceptedEnabled,
    isViewEnabled,
    isCancelRequestEnabled,
  } = useInsideSalesListPermissions();

  const enableRowSelection = ({ original }) => (
    !original?.acceptedDatetime && original?.engagementType !== EngagementType.CANCELED
  );

  return (
    <TableActiveRowContextProvider>
      <div className="inside-sales-list">
        <TableProvider
          id={tableIds.insideSales}
          getDataAction={getInsideSalesAction}
          columns={columns as ColumnDef<TRow>[]}
          enableEditing={isEditEnabled || isViewEnabled || isCancelRequestEnabled}
          enableRowSelection={isRequestAcceptedEnabled && enableRowSelection}
        >
          <InsideSalesListTable />
        </TableProvider>
      </div>
    </TableActiveRowContextProvider>
  );
};

export default InsideSalesList;
