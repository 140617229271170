import Yup from 'core/utilities/Yup';
import { US_STATES } from 'core/models/states.models';
import { EFFECTIVE_PERIOD } from 'pages/Customers/Customers.models';
import { getOneOfFromType } from 'core/utilities/Yup/YupHelpers';

const billingInfoSchema = Yup.object().shape({
  address: Yup
    .string()
    .required('Address is required')
    .default(''),
  suite: Yup
    .string()
    .default(''),
  city: Yup
    .string()
    .required('City is required')
    .default(''),
  state: Yup
    .string()
    .oneOf(getOneOfFromType(US_STATES))
    .required('State is required')
    .default(''),
  zipCode: Yup
    .string()
    .matches(/^\d*$/, 'Should be a number')
    .max(5, 'Should be no more than 5 digits')
    .required('Zip code is required')
    .default(''),

});

const loaValidationContextFieldName = 'areAllRequired';
export const loaInfoProfileSchemaOptions = { context: { [loaValidationContextFieldName]: true } };

const profileSchema = Yup.object().shape({
  firstName: Yup
    .string()
    .nullable()
    // @ts-ignore
    .requiredConditionally(`$${loaValidationContextFieldName}`, 'First name is required')
    .default(''),
  lastName: Yup
    .string()
    .nullable()
    // @ts-ignore
    .requiredConditionally(`$${loaValidationContextFieldName}`, 'Last name is required')
    .default(''),
  title: Yup
    .string()
    .nullable()
    // @ts-ignore
    .requiredConditionally(`$${loaValidationContextFieldName}`, 'Title is required')
    .default(''),
  email: Yup
    .string()
    .nullable()
    // @ts-ignore
    .requiredConditionally(`$${loaValidationContextFieldName}`, 'Email is required')
    .email('Invalid email address')
    .default(''),
  phoneNumber: Yup
    .string()
    .nullable()
    // @ts-ignore
    .requiredConditionally(`$${loaValidationContextFieldName}`, 'Phone number is required')
    .default(''),
});

export const loaInfoProfileSchema = profileSchema.shape({
  effectivePeriod: Yup
    .string()
    .required('Effective period is required')
    .oneOf([...getOneOfFromType(EFFECTIVE_PERIOD), ''])
    .default(EFFECTIVE_PERIOD.THREE_YEARS),
});

const customersCreateFormSchema = Yup.object().shape({
  id: Yup
    .number()
    .required('Customer ID is required')
    .default(null),
  seller: Yup
    .string()
    .required('Seller is required')
    .default(''),
  sellerOptionalFirst: Yup
    .string()
    .default(''),
  sellerOptionalSecond: Yup
    .string()
    .default(''),
  companyName: Yup
    .string()
    .required('Company name is required')
    .default(''),
  subAccountId: Yup
    .string()
    .max(20, 'Should be no more than 20 digits')
    .matches(/^\d*$/, 'Should be a number')
    .default(''),
  billingInfo: billingInfoSchema,
  loaInfoProfile: loaInfoProfileSchema,
  contractSignerProfile: profileSchema,
  primaryItProfile: profileSchema,
  alternativeContactProfile: profileSchema,
});

export default customersCreateFormSchema;
