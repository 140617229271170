import React, { useState, useEffect } from 'react';
import { defaultPeriod } from '@dashboardWirelinePMDepartment/constants';
import TransactionalOrdersChart
  from '@dashboardWirelineContractSpecialistDepartment/TransactionalOrdersChart';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { HTTPService } from 'core/services';
import { getTransactionalOrdersAction } from '@dashboardWirelineContractSpecialistDepartment/GetTransactionalOrders';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useAppDispatch } from 'core/store';
import { notifierMessage, TransactionalOrdersProducts } from '@dashboardWirelineContractSpecialistDepartment/constants';
import OpenRequestsChart from '@dashboardWirelineContractSpecialistDepartment/OpenRequestsChart';
import { getOpenRequestsAction } from '@dashboardWirelineContractSpecialistDepartment/GetOpenRequests';
import './ContractSpecialistDepartment.scss';

let transactionalOrdersController = HTTPService.getController();
let openRequestsController = HTTPService.getController();

const ContractSpecialistDepartment = () => {
  const { showErrorNotifier } = useNotifiers();

  const dispatch = useAppDispatch();

  const [period, setPeriod] = useState(defaultPeriod);

  const cancelTransactionalOrdersRequest = () => {
    HTTPService.cancelRequest(transactionalOrdersController);
  };

  const cancelOpenRequestsRequest = () => {
    HTTPService.cancelRequest(openRequestsController);
  };

  const getTransactionalOrders = (newPeriod) => {
    cancelTransactionalOrdersRequest();

    transactionalOrdersController = HTTPService.getController();

    const params = {
      products: TransactionalOrdersProducts.join(','),
      ...newPeriod,
    };

    dispatch(getTransactionalOrdersAction({
      controller: transactionalOrdersController, params,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.transactionalOrders.error, e);
      });
  };

  const getOpenRequestsOrders = (newPeriod) => {
    cancelOpenRequestsRequest();

    openRequestsController = HTTPService.getController();

    dispatch(getOpenRequestsAction({
      controller: openRequestsController, params: newPeriod,
    }))
      .unwrap()
      .catch((e) => {
        showErrorNotifier(notifierMessage.openRequests.error, e);
      });
  };

  const updateCharts = (newPeriod) => {
    getTransactionalOrders(newPeriod);
    getOpenRequestsOrders(newPeriod);

    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelTransactionalOrdersRequest();
    cancelOpenRequestsRequest();
  };

  useEffect(() => {
    updateCharts(period);

    return () => cancelRequests();
  }, []);

  return (
    <div className="contract-specialist">
      <div className="contract-specialist__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </div>
      <div className="contract-specialist__charts">
        <TransactionalOrdersChart />
        <OpenRequestsChart />
      </div>
    </div>
  );
};

export default ContractSpecialistDepartment;
