import Yup from 'core/utilities/Yup';

const scopeDescriptionSchema = Yup
  .object()
  .shape({
    text: Yup
      .string()
      .max(1000, 'Scope Description must be less than 1000 characters'),
  })
  .default({ text: '' });

const wirelineEngagementsCreateFormSchema = Yup
  .object()
  .shape({
    engagementType: Yup
      .number()
      .test(
        'Engagement Type is required',
        'Engagement Type is required',
        (engagementType) => {
          if (!engagementType || ![0, 1].includes(engagementType)) {
            return (
              new Yup.ValidationError('Engagement Type is required')
            );
          }

          return true;
        })
      .required('Engagement Type is required')
      .default(1),
    companyName: Yup
      .string()
      .required('Customer Name is required')
      .default(''),
    subAgentName: Yup
      .string()
      .default(''),
    bdsSupport: Yup
      .string()
      .default(''),
    directSeller: Yup
      .string()
      .default(''),
    technicalOverlay: Yup
      .string()
      .email()
      .required('Technical Overlay is required')
      .default(''),
    scopeDescription: scopeDescriptionSchema,
  });

export default wirelineEngagementsCreateFormSchema;
