import { useIsUserType } from 'core/hooks/useIsUserType';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment, UserType } from 'pages/UserManagement/UserManagement.models';

const { INSIDE_SALES, SELLER } = UserTeamRoleTeamDepartment;

const useInsideSalesListPermissions = () => {
  const isAdmin = useIsUserType(UserType.ADMIN);
  const isInsideSales = useIsTeamRolesDepartmentMember(INSIDE_SALES);
  const isSeller = useIsTeamRolesDepartmentMember(SELLER);

  const isRequestAcceptedEnabled = isInsideSales;

  const isEditEnabledAcceptedOnly = isInsideSales || isAdmin;
  const isEditEnabled = isAdmin || isEditEnabledAcceptedOnly;
  const isCreateEnabled = isAdmin || isSeller;
  const isAbleToMarkRequestAsCompleted = isInsideSales || isAdmin;
  const isCancelRequestEnabled = isAdmin || isSeller;
  const isViewEnabled = isSeller || !isEditEnabled;
  const isFieldViewEnabled = !isSeller;

  return {
    isEditEnabledAcceptedOnly,
    isEditEnabled,
    isCreateEnabled,
    isRequestAcceptedEnabled,
    isAbleToMarkRequestAsCompleted,
    isCancelRequestEnabled,
    isViewEnabled,
    isFieldViewEnabled,
  };
};

export default useInsideSalesListPermissions;
