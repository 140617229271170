import styles from 'assets/styles/_resources.scss';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

export const colorsOfPMCharts = [
  styles.secondaryColor,
  styles.primaryColor,
  styles.blueColor,
  styles.tealColor500,
];

export const notifierMessage = {
  projectAcceptance: {
    error: 'Failed to complete your request for Project Acceptance , please try again later.',
  },
  projectStatus: {
    error: 'Failed to complete your request for Project Status , please try again later.',
  },
  generalEngagement: {
    error: 'Failed to complete your request for General Engagement , please try again later.',
  },
};

export const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.THREE_MONTHS);
