import React, { useEffect } from 'react';
import cx from 'classnames';
import MaterialReactTable, {
  // eslint-disable-next-line no-unused-vars
  type MRT_Row as Row,
  // eslint-disable-next-line no-unused-vars
  type MRT_Cell as Cell, type MRT_TableInstance as TableInstance,
  MRT_ColumnPinningState as ColumnPinningState,
  MRT_VisibilityState as VisibilityState,
  MRT_DisplayColumnIds as DisplayColumnIds,
  MRT_ColumnDef as ColumnDef,
} from 'material-react-table';
import { OutlinedTextFieldProps } from '@mui/material';
import get from 'lodash/get';
import { ITableContainerProps, TRow } from 'shared/Table/Table.models';
import TableToolbar from 'shared/Table/TableToolbar';
import { tableStyles } from 'shared/Table/tableStyles';
import { useTableContext } from 'shared/Table/TableContext';
import { rowsPerPageOptions } from 'shared/Table/constants';

type DisplayColumnDefOptions = Partial<{
  [key in DisplayColumnIds]: Partial<ColumnDef>;
}>

type RenderRowActions = (props: {
  cell: Cell<TRow>
  row: Row<TRow>
  table: TableInstance<TRow>
}) => React.ReactNode

interface ITableProps {
  className?: string;
  rows: TRow[];
  rowCount: number;
  toolBarAction?: JSX.Element;
  handleRowClick?: (row: Row<TRow>) => void;
  showColumnFilters: boolean;
  showGlobalFilter: boolean;
  manualFiltering?: boolean;
  manualPagination?: boolean;
  manualSorting?: boolean;
  enableHiding? : boolean;
  enablePinning?: boolean;
  enableEditing?: boolean;
  enableGlobalFilter? : boolean;
  isLoading: boolean;
  showFilterButton?: boolean;
  enableColumnActions?: boolean;
  enableSelectAll?: boolean;
  actionsButton?: JSX.Element;
  selectedRowIdName?: string;
  showToolbar?: boolean;
  toolBarCustomSearch?: JSX.Element,
  defaultVisibility?: VisibilityState;
  enableStickyHeader?: boolean;
  initialColumnPinning?: ColumnPinningState;
  muiTableContainerProps?: ITableContainerProps;
  renderRowActions?: RenderRowActions;
  displayColumnDefOptions?: DisplayColumnDefOptions;
  renderBottomToolbarCustomActions?: () => React.ReactNode;
}

const Table = ({
  className,
  isLoading, rows, rowCount, toolBarAction, handleRowClick, showColumnFilters,
  showGlobalFilter, manualFiltering, manualPagination, manualSorting,
  enableGlobalFilter, enablePinning, enableHiding, showFilterButton, enableColumnActions,
  enableSelectAll, actionsButton, selectedRowIdName, showToolbar, toolBarCustomSearch,
  enableStickyHeader, defaultVisibility, initialColumnPinning, muiTableContainerProps,
  renderRowActions, displayColumnDefOptions, ...rest
}: ITableProps) => {
  const {
    columns,
    getTableData,
    cancelGetTableDataRequest,
    columnOrder,
    setColumnOrder,
    setColumnFilters,
    setGlobalFilter,
    setSorting,
    setPagination,
    enableRowSelection,
    enableEditing,
    rowSelection,
    setRowSelection,
    tableFilters,
  } = useTableContext();

  useEffect(() => {
    getTableData();

    return () => cancelGetTableDataRequest();
  }, [tableFilters]);

  const renderTopToolbar = ({ table }) => showToolbar && (
    <TableToolbar
      table={table}
      toolBarAction={toolBarAction}
      showFilterButton={showFilterButton as boolean}
      actionsButton={actionsButton}
      toolBarCustomSearch={toolBarCustomSearch}
    />
  );

  const muiTableBodyRowProps = ({ row }: { row: Row<TRow>}) => ({
    onClick: () => handleRowClick?.(row),
    ...tableStyles.muiTableBodyRowProps,
  });

  return (
    <div className={cx('table', className)}>
      <MaterialReactTable
        columns={columns}
        data={rows}
        rowCount={rowCount}
        initialState={{
          showColumnFilters,
          columnPinning: initialColumnPinning as ColumnPinningState,
        }}
        state={{
          showGlobalFilter,
          showProgressBars: isLoading,
          isLoading,
          columnFilters: tableFilters.filters,
          globalFilter: tableFilters.search,
          pagination: tableFilters.pagination,
          sorting: tableFilters.sorting,
          columnOrder,
          rowSelection,
          columnVisibility: defaultVisibility as VisibilityState,
        }}
        getRowId={(row) => get(row, selectedRowIdName as string) as string}
        onRowSelectionChange={setRowSelection}
        manualFiltering={manualFiltering as boolean}
        manualPagination={manualPagination as boolean}
        manualSorting={manualSorting as boolean}
        muiTablePaginationProps={{
          rowsPerPageOptions,
        }}
        // enable features
        enableRowSelection={enableRowSelection as boolean}
        enableColumnOrdering
        enableHiding={enableHiding as boolean}
        enablePinning={enablePinning as boolean}
        enableGlobalFilter={enableGlobalFilter as boolean}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        enableColumnActions={enableColumnActions as boolean}
        onPaginationChange={setPagination}
        onColumnOrderChange={setColumnOrder}
        onSortingChange={setSorting}
        enableEditing={enableEditing as boolean}
        enableSelectAll={enableSelectAll as boolean}
        enableStickyHeader={enableStickyHeader as boolean}
        renderRowActions={renderRowActions as RenderRowActions}
        displayColumnDefOptions={displayColumnDefOptions as DisplayColumnDefOptions}
        // toolbar
        renderTopToolbar={renderTopToolbar}
          // styles
        muiTableBodyRowProps={muiTableBodyRowProps}
        muiTableContainerProps={muiTableContainerProps as ITableContainerProps}
        muiTableHeadCellFilterTextFieldProps={
            tableStyles.muiTableHeadCellFilterTextFieldProps as OutlinedTextFieldProps
          }
        positionGlobalFilter={tableStyles.positionGlobalFilter}
        muiSearchTextFieldProps={tableStyles.muiSearchTextFieldProps}
        muiTablePaperProps={tableStyles.muiTablePaperProps}
        muiTableBodyProps={tableStyles.muiTableBodyProps}
        muiTableBodyCellProps={tableStyles.muiTableBodyCellProps}
        muiLinearProgressProps={tableStyles.muiLinearProgressProps}
        muiTableHeadCellProps={tableStyles.muiTableHeadCellProps}
        icons={tableStyles.icons}
        layoutMode={tableStyles.layoutMode}
        {...rest}
      />
    </div>
  );
};

Table.defaultProps = {
  className: '',
  manualFiltering: true,
  manualPagination: true,
  manualSorting: true,
  enableHiding: false,
  enablePinning: true,
  enableGlobalFilter: true,
  showFilterButton: false,
  enableColumnActions: false,
  handleRowClick: null,
  enableEditing: false,
  enableSelectAll: false,
  actionsButton: null,
  selectedRowIdName: 'id',
  toolBarAction: null,
  showToolbar: true,
  toolBarCustomSearch: null,
  defaultVisibility: {},
  enableStickyHeader: false,
  initialColumnPinning: {},
  muiTableContainerProps: {},
  renderRowActions: undefined,
  displayColumnDefOptions: undefined,
  renderBottomToolbarCustomActions: undefined,
};

export default Table;
