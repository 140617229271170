import React from 'react';
import get from 'lodash/get';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import { columns } from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTable/constants';
import BookingTable from '@dashboardWirelineSellerDepartment/BookingInfo/BookingTable';
import { TableProvider } from 'shared/Table/TableContext';
import { useSellerDepartmentContext } from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';
// eslint-disable-next-line no-unused-vars
import { TPeriodBooking } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { tableIds } from 'shared/Table/constants';
import { BookingStatus } from '@EngagementsSowData/sowData.model';
import getBookingDataAction from '@dashboardWirelineSellerDepartment/GetBookingData/actions';
import './BookingInfo.scss';

const { SELLER, PROJECT_MANAGER } = UserTeamRoleTeamDepartment;

const BookingInfo = () => {
  const { period } = useSellerDepartmentContext();

  const isSellerSpecialist = useIsTeamRolesDepartmentMember(SELLER);
  const isProjectMenagerSpecialist = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const excludeSpecialist = isSellerSpecialist || isProjectMenagerSpecialist;

  const enableRowSelection = (
    (row) => (
      !excludeSpecialist && get(row, 'original.bookingStatus') !== BookingStatus.BOOKED
    )
  );

  return (
    <TableProvider<TPeriodBooking>
      id={tableIds.bookingInfo}
      columns={columns}
      getDataAction={getBookingDataAction}
      enableRowSelection={enableRowSelection}
      requestParams={{
        createdDatetimeAfter: period.dateFrom,
        createdDatetimeBefore: period.dateTo,
      }}
    >
      <BookingTable />
    </TableProvider>
  );
};

export default BookingInfo;
