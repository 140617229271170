import React from 'react';
import { useFormikContext } from 'formik';
import CustomSidebar from 'shared/CustomSidebar';
import EditRowSidebarFields from 'pages/InsideSales/InsideSalesList/InsideSalesListTable/InsideSalesListTableModals/InsideSalesEditRowSidebarForm/EditRowSidebarFields';
import { editRequestTitle, viewRequestTitle } from 'pages/InsideSales/InsideSalesList/constants';

interface IEditRowSidebarProps {
  isViewMode: boolean
  isLoadingItem: boolean
  cleanEditRow: () => void
  showStatusField: boolean
  editRowId: string | number | undefined | null
}

const EditRowSidebar: React.FC<IEditRowSidebarProps> = ({
  editRowId, isLoadingItem, isViewMode, showStatusField, cleanEditRow,
}) => {
  const { submitForm, setErrors, resetForm } = useFormikContext();

  const closeEditRowSidebar = () => {
    cleanEditRow();
    setErrors({});
    resetForm();
  };

  const sidebarTitle = isViewMode ? viewRequestTitle : editRequestTitle;

  return (
    <CustomSidebar
      isOpen={!!editRowId}
      onCloseSideBar={closeEditRowSidebar}
      title={sidebarTitle}
      onClickSave={submitForm}
      areButtonsVisible={!isViewMode as boolean}
      isConfirmDialog={!isViewMode as boolean}
    >
      <EditRowSidebarFields
        editRowId={editRowId}
        isViewMode={isViewMode}
        isLoadingItem={isLoadingItem}
        showStatusField={showStatusField}
        isDisabled={isViewMode as boolean}
      />
    </CustomSidebar>
  );
};

export default EditRowSidebar;
