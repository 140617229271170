import React from 'react';
import BookedOrderButton from '@dashboardWirelineSellerDepartment/BookingInfo/BookedOrder/BookedOrderButton';
import { Box, Typography } from '@mui/material';

const BookedOrderTopToolbar = () => (
  <Box
    className="table__toolbar"
    data-testid="table-toolbar-test-id"
  >
    <Box className="bookingInfo">
      <Typography variant="h3">Booking Table</Typography>
      <BookedOrderButton />
    </Box>
  </Box>
);

export default BookedOrderTopToolbar;
