import { getSalesSowMeta } from 'core/api/sales.api';
import {
  getSalesSowMetaRequest,
  getSalesSowMetaSuccess,
  getSalesSowMetaFailure,
} from 'core/actions/Sales/GetSalesSowMeta/actionsCreators';

const getSalesSowMetaAction = () => async (dispatch) => {
  try {
    dispatch(getSalesSowMetaRequest());

    const { data } = await getSalesSowMeta();

    dispatch(getSalesSowMetaSuccess(data));
  } catch (error) {
    dispatch(getSalesSowMetaFailure(error));

    throw error;
  }
};

export default getSalesSowMetaAction;
