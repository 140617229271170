import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import './EngagementAcceptedItems.scss';

interface IEngagementAcceptedItemsProps {
  selectedActiveNumbers: IActiveNumbersItem[];
}

const EngagementAcceptedItems: React.FC<IEngagementAcceptedItemsProps> = ({
  selectedActiveNumbers,
}) => (
  <div className="engagement-accepted-items">
    {selectedActiveNumbers.map(({ location, product, id }) => (
      <div className="engagement-accepted-item__wrap" key={id}>
        <div className="engagement-accepted-item__location-wrap">
          <CheckCircleOutlineIcon className="engagement-accepted-item__icon" />
          <span className="engagement-accepted-item__location">
            {location}
          </span>
        </div>
        <span className="engagement-accepted-item__product">
          {product}
        </span>
      </div>
    ))}
  </div>
);

export default EngagementAcceptedItems;
